<template>
  <v-card
    width="260px"
    :color="isActive ? primaryColor : ''"
    :dark="isActive"
    outlined
    ripple
    hover
    class="rentpress-shortcode-unit-card pa-2"
  >
    <v-card-text
      class="rentpress-inherited-font-family pa-0 ma-0 text-h6"
      align="left"
    >
      <span
        :class="
          isActive
            ? 'font-weight-bold rentpress-no-wrap-text'
            : 'font-weight-bold secondary--text rentpress-no-wrap-text'
        "
      >
        {{ unit.unit_name }}
      </span>
      <div>
        <span
          v-if="
            unit.unit_rent_type_selection_cost &&
              unit.unit_rent_type_selection_cost > 100 &&
              !disabledPrice
          "
          class="rentpress-inherited-font-family text-subtitle-1"
        >
          {{ this.formatter.format(unit.unit_rent_type_selection_cost) }}/mo
        </span>
        <span v-else>
          {{
            options.rentpress_disable_pricing_section_disable_pricing_message
          }}
        </span>
        <span
          v-if="unit.unit_sqft"
          class="rentpress-inherited-font-family text-subtitle-1"
        >
          • {{ unit.unit_sqft }} sq. ft.
        </span>
      </div>
      <div>
        <span
          v-if="unitAvailable"
          class="rentpress-inherited-font-family text-subtitle-1"
        >
          Available Now
        </span>
        <span v-else class="rentpress-inherited-font-family text-subtitle-1">
          Available On {{ unit.unit_ready_date }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "unit-card",
  data: function() {
    return {
      unitAvailable: false
    };
  },
  props: {
    unit: Object,
    options: Object,
    isActive: Boolean,
    formatter: Intl.NumberFormat,
    primaryColor: String,
    disabledPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  mounted: function() {
    var today = new Date();
    this.unitAvailable = Date.parse(this.unit.unit_ready_date) < today;
  }
};
</script>
<style>
.v-application .rentpress-shortcode-unit-card {
  background-color: transparent;
  border-width: medium !important;
}
</style>
