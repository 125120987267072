var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('iframe',{staticStyle:{"border":"0"},attrs:{"title":"Map of the properties neighborhood","width":"100%","height":"500","frameborder":"0","src":"https://www.google.com/maps/embed/v1/place?key=" +
      _vm.options.rentpress_google_maps_api_section_api_key +
      "&zoom=15&q=" +
      _vm.property.property_name +
      "," +
      _vm.property.property_address +
      "," +
      _vm.property.property_city +
      "," +
      _vm.property.property_state}})])}
var staticRenderFns = []

export { render, staticRenderFns }