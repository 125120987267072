<template>
  <div class="rentpress-shortcode-single-property-wrapper">
    <v-banner
      v-if="
        property.property_specials_message &&
          property.property_specials_expiration >= todaysDate
      "
      elevation="10"
      :color="
        property.property_accent_color &&
        property.property_use_accent_color === '1'
          ? property.property_accent_color
          : 'primary'
      "
      dark
      class="rentpress-inherited-font-family text-h6 text-center rentpress-single-property-specials-banner"
      style="z-index: 1"
    >
      <div
        @click="
          navigateToExternalSite(property.property_specials_link);
          sendGAEvent('View Special', 'click', property.property_name);
        "
        :style="property.property_specials_link ? 'cursor:pointer;' : ''"
      >
        <span class="font-weight-black">
          <v-icon class="pb-2">mdi-star</v-icon>
          SPECIAL:
        </span>
        {{ property.property_specials_message }}
      </div>
    </v-banner>
    <!-- Featured Image row -->
    <v-row no-gutters align="center">
      <v-col xl="9" lg="8" md="12" sm="12">
        <v-img
          :src="featuredImage"
          :srcset="featuredImageSrcset"
          max-height="600px"
        ></v-img>
      </v-col>
      <v-col xl="3" lg="4" md="12" sm="12" class="text-left px-2">
        <v-list-item>
          <v-list-item-content>
            <h1
              class="rentpress-inherited-font-family secondary--text text-wrap text-h4 rentpress-single-property-title"
              v-html="property.property_name"
            ></h1>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          three-line
          class="rentpress-inherited-font-family text-subtitle-1"
        >
          <v-list-item-content>
            <v-list-item-title
              class="rentpress-inherited-font-family text-subtitle-1"
            >
              {{ property.property_address }}
            </v-list-item-title>
            <v-list-item-title
              class="rentpress-inherited-font-family text-subtitle-1"
            >
              {{ property.property_city }}, {{ property.property_state }}
              {{ property.property_zip }}
            </v-list-item-title>
            <v-list-item-title
              class="rentpress-inherited-font-family text-subtitle-1"
            >
              <v-btn
                color="secondary"
                small
                plain
                tile
                text
                @click="
                  navigateToExternalSite(directionsAddress);
                  sendGAEvent(
                    'Get Directions',
                    'click',
                    property.property_name
                  );
                "
              >
                <v-icon left small>mdi-map</v-icon>
                Directions
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          class="rentpress-inherited-font-family text-subtitle-1"
        >
          <v-list-item-content>
            <v-list-item-title
              class="rentpress-inherited-font-family text-subtitle-1"
            >
              {{ displayBedroomRange }}
              <span
                v-if="
                  property.property_rent_type_selection ==
                    'Minimum - Maximum' &&
                    property.property_rent_min &&
                    property.property_rent_min > 100 &&
                    property.property_rent_max &&
                    property.property_rent_max > 100 &&
                    property.property_rent_min != property.property_rent_max
                "
              >
                |
                {{ formatter.format(property.property_rent_min) }} -
                {{ formatter.format(property.property_rent_max) }}
              </span>
              <span
                v-else-if="
                  property.property_rent_type_selection_cost &&
                    property.property_rent_type_selection_cost > 100
                "
              >
                | Starting at
                {{
                  formatter.format(property.property_rent_type_selection_cost)
                }}
              </span>
              <span
                v-else-if="
                  options.rentpress_disable_pricing_section_disable_pricing_message &&
                    options.rentpress_disable_pricing_section_disable_pricing_message !==
                      ''
                "
              >
                |
                {{
                  options.rentpress_disable_pricing_section_disable_pricing_message
                }}
              </span>
            </v-list-item-title>
            <v-list-item-title
              class="rentpress-inherited-font-family text-subtitle-1"
            >
              {{ displayPetText }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center">
          <v-col v-if="property.property_phone_number" cols="auto">
            <v-btn
              color="secondary"
              small
              plain
              tile
              text
              @click="
                dial(property.property_phone_number);
                sendGAEvent('Phone Call', 'click', property.property_name);
              "
            >
              <v-icon left small>mdi-cellphone</v-icon>
              {{ displayPhoneNumber }}
            </v-btn>
          </v-col>
          <v-col v-if="property.property_email" cols="auto">
            <v-btn
              color="secondary"
              small
              plain
              tile
              text
              @click="
                sendMail(property.property_email);
                sendGAEvent('Send Mail', 'click', property.property_name);
              "
            >
              <v-icon left small>mdi-email</v-icon>
              Email Us
            </v-btn>
          </v-col>
          <v-col v-if="property.property_website" cols="auto">
            <v-btn
              color="secondary"
              small
              plain
              tile
              text
              @click="
                navigateToExternalSite(property.property_website);
                sendGAEvent(
                  'Visit Property Website',
                  'click',
                  property.property_name
                );
              "
            >
              <v-icon left small>mdi-arrow-right-thin-circle-outline</v-icon>
              Website
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="this.options.rentpress_application_link_section_url_tour != ''"
          justify="center"
          no-gutters
        >
          <v-col cols="12">
            <v-btn
              block
              elevation="4"
              :color="
                property.property_accent_color &&
                property.property_use_accent_color === '1'
                  ? property.property_accent_color
                  : 'primary'
              "
              large
              @click="
                navigateTo(tourLink);
                sendGAEvent('Schedule Tour', 'click', property.property_name);
              "
              class="rentpress-application-link-section-url-tour-button white--text"
            >
              Schedule Tour
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="6" md="6" sm="6">
            <div v-if="property.property_contact_type == '3'">
              <v-btn
                class="white--text"
                block
                :color="
                  property.property_accent_color &&
                  property.property_use_accent_color === '1'
                    ? property.property_accent_color
                    : 'primary'
                "
                large
                :outlined="
                  this.options.rentpress_application_link_section_url_tour != ''
                "
                @click="
                  openGravityFormsModal(property.property_post_id);
                  sendGAEvent(
                    'Contact Leasing',
                    'click',
                    property.property_name
                  );
                "
              >
                Contact Leasing 1
              </v-btn>
              <v-dialog
                v-model="propertyGravityForm"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
              >
                <v-card tile>
                  <v-btn
                    color="#303030"
                    fab
                    dark
                    small
                    absolute
                    top
                    right
                    @click="propertyGravityForm = ''"
                    class="mt-6 mr-n3"
                    elevation="10"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <div
                    class="floorplan-form-pop-up"
                    v-html="floorplanForm"
                    ref="gravityform"
                  ></div>
                </v-card>
              </v-dialog>
            </div>
            <div v-else>
              <v-btn
                block
                :color="
                  property.property_accent_color &&
                  property.property_use_accent_color === '1'
                    ? property.property_accent_color
                    : 'primary'
                "
                large
                :outlined="
                  this.options.rentpress_application_link_section_url_tour != ''
                "
                @click="
                  navigateTo(contactLink);
                  sendGAEvent(
                    'Contact Leasing',
                    'click',
                    property.property_name
                  );
                "
              >
                Contact Leasing
              </v-btn>
            </div>
          </v-col>
          <v-col v-if="applyLink" xl="6" lg="6" md="6" sm="6">
            <v-btn
              block
              :color="
                property.property_accent_color &&
                property.property_use_accent_color === '1'
                  ? property.property_accent_color
                  : 'primary'
              "
              large
              outlined
              @click="
                navigateToExternalSite(applyLink);
                sendGAEvent('Apply Now', 'click', property.property_name);
              "
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="auto"
            v-if="
              property.property_twitter_link ||
                property.property_facebook_link ||
                property.property_instagram_link
            "
          >
            <p
              class="rentpress-inherited-font-family font-weight-bold secondary--text pt-1"
            >
              FIND US
            </p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="property.property_facebook_link"
              icon
              @click="
                navigateToExternalSite(property.property_facebook_link);
                sendGAEvent(
                  'Social - Facebook',
                  'click',
                  property.property_name
                );
              "
            >
              <v-icon class="white--text secondary">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              v-if="property.property_twitter_link"
              icon
              @click="
                navigateToExternalSite(property.property_twitter_link);
                sendGAEvent(
                  'Social - Twitter',
                  'click',
                  property.property_name
                );
              "
            >
              <v-icon class="white--text secondary">mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              v-if="property.property_instagram_link"
              icon
              @click="
                navigateToExternalSite(property.property_instagram_link);
                sendGAEvent(
                  'Social - Instagram',
                  'click',
                  property.property_name
                );
              "
            >
              <v-icon class="white--text secondary">mdi-instagram</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="property.property_residents_link">
            <v-btn
              color="grey"
              small
              plain
              tile
              text
              @click="navigateToExternalSite(property.property_residents_link)"
            >
              <v-icon left small>mdi-arrow-right-thin-circle-outline</v-icon>
              Residents Portal
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- End of featured Image row -->
    <div
      class="text-center rentpress--on-page-nav"
      style="overflow-x: auto; white-space: nowrap"
      v-bind:style="propertyPrimaryStyle"
    >
      <v-btn
        text
        dark
        class="font-weight-bold rentpress--on-page-nav--link"
        @click="$vuetify.goTo('#amenities', scrollOptions)"
      >
        Amenities
      </v-btn>
      <v-btn
        v-if="hasFloorplans"
        text
        dark
        class="font-weight-bold rentpress--on-page-nav--link"
        @click="$vuetify.goTo('#floorplans', scrollOptions)"
      >
        Floor Plans
      </v-btn>
      <v-btn
        v-if="!hideNeighborhood"
        text
        dark
        class="font-weight-bold rentpress--on-page-nav--link"
        @click="$vuetify.goTo('#neighborhood', scrollOptions)"
      >
        Neighborhood
      </v-btn>
      <v-btn
        v-if="
          (galleryImages && galleryImages.length > 0) || hasGalleryShortcode
        "
        text
        dark
        class="font-weight-bold rentpress--on-page-nav--link"
        @click="$vuetify.goTo('#gallery', scrollOptions)"
      >
        Gallery
      </v-btn>
    </div>
    <v-card color="#F9F9F9" outlined>
      <v-container class="rentpress--single-property--main-content-container">
        <v-row class="rentpress-inherited-font-family text-left">
          <v-col xl="12" cols="auto" v-if="property.property_description">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family secondary--text text-h6 rentpress-single-property-about-title rentpress--main-content-container--subtitle"
                >
                  About Our Community
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family text-wrap text-body-1 pre-formatted"
                  v-html="property.property_description"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            xl="12"
            cols="auto"
            id="amenities"
            v-if="amenitiesList.length > 0"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family secondary--text text-h6 rentpress-single-property-amenities-title rentpress--main-content-container--subtitle"
                >
                  Amenities
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-row no-gutters justify="space-between">
              <v-col
                xl="12"
                cols="auto"
                class="rentpress-property-amenities-list"
              >
                <div
                  v-for="(amenity, index) in amenitiesList"
                  :key="index"
                  class="rentpress-inherited-font-family text-body-2 rentpress--main-content-container--list-item"
                >
                  <v-icon class="secondary--text">mdi-chevron-right</v-icon>
                  {{ amenity }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            xl="12"
            cols="auto"
            id="features"
            v-if="featuresList.length > 0"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family secondary--text text-h6 rentpress-single-property-features-title rentpress--main-content-container--subtitle"
                >
                  Features
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-row no-gutters justify="space-between">
              <v-col
                xl="12"
                cols="auto"
                class="rentpress-property-amenities-list"
              >
                <div
                  v-for="(feature, index) in featuresList"
                  :key="index"
                  class="rentpress-inherited-font-family text-body-2 rentpress--main-content-container--list-item"
                >
                  <v-icon class="secondary--text">mdi-chevron-right</v-icon>
                  {{ feature }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col
            xl="8"
            lg="8"
            md="12"
            sm="12"
            v-if="property.property_pet_policy"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family secondary--text text-h6"
                  v-html="property.property_name + ' Pet Policy'"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family text-wrap text-body-1 pre-formatted"
                  v-html="property.property_pet_policy"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            xl="4"
            lg="4"
            md="12"
            sm="12"
            v-if="hasOfficeHours"
            class="rentpress-office-hours-container"
          >
            <v-list-item class="rentpress-office-hours-title">
              <v-list-item-content>
                <v-list-item-title
                  class="rentpress-inherited-font-family secondary--text text-h6 rentpress-single-property-hours-title rentpress--main-content-container--subtitle"
                >
                  Office Hours
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div class="rentpress-office-hours-list">
              <div v-for="(officeHour, day) in officeHours" :key="day">
                <div
                  v-if="officeHour"
                  class="rentpress-inherited-font-family text-body-2 rentpress--main-content-container--list-item"
                  :class="today == day ? 'font-weight-black' : ''"
                >
                  <v-icon class="secondary--text">mdi-chevron-right</v-icon>
                  {{ day }}: <span v-html="officeHour"></span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div v-if="hasFloorplans" id="floorplans" class="ma-3">
      <v-row justify="center" class="my-5">
        <v-col cols="auto">
          <h3
            class="rentpress-inherited-font-family secondary--text text-h5 rentpress-single-property-floorplan-section-title"
            v-html="property.property_name + ' Floor Plans'"
          ></h3>
        </v-col>
      </v-row>
      <floorplan-search
        v-if="
          floorplansWithPropertyData[0] &&
            floorplansWithPropertyData[0].floorplan_code
        "
        :floorplans="floorplansWithPropertyData"
        :parentproperties="[property]"
        :options="options"
        :useModals="useModals || hasGravityForm"
        :formatter="formatter"
        :sidebarFilters="true"
        :useEngrain="true"
      />
    </div>
    <div id="neighborhood" v-if="!hideNeighborhood">
      <v-row
        v-if="neighborhoodData.post"
        no-gutters
        align="center"
        justify="center"
      >
        <v-col v-if="showMap" xl="9" lg="8" md="12" sm="12" cols="12">
          <slot name="map" />
        </v-col>
        <v-col v-else xl="9" lg="8" md="12" sm="12" cols="12">
          <v-img
            :src="neighborhoodData.image_src || cityPlaceholderImage"
            :srcset="neighborhoodData.image_srcset"
            max-height="50vh"
          ></v-img>
        </v-col>
        <v-col
          xl="3"
          lg="4"
          md="12"
          sm="12"
          class="rentpress-inherited-font-family text-left px-2"
        >
          <p class="rentpress-inherited-font-family text-h6 my-5">
            {{ property.property_name }}
          </p>
          <h4 class="rentpress-inherited-font-family text-h4 my-10">
            {{ neighborhoodData.post.post_title }}
          </h4>
          <p
            v-if="
              neighborhoodData.rentpress_custom_field_neighborhood_romance_copy &&
                neighborhoodData
                  .rentpress_custom_field_neighborhood_romance_copy[0]
            "
            class="rentpress-inherited-font-family text-body-1 mt-10"
            max-height="300px"
            style="max-height: 240px; overflow-y: auto"
          >
            {{
              neighborhoodData
                .rentpress_custom_field_neighborhood_romance_copy[0]
            }}
          </p>
          <v-btn
            class="white--text"
            block
            elevation="4"
            :color="
              property.property_accent_color &&
              property.property_use_accent_color === '1'
                ? property.property_accent_color
                : 'primary'
            "
            large
            @click="
              navigateTo(neighborhoodData.post.guid);
              sendGAEvent(
                'Learn More',
                'click',
                neighborhoodData.post.post_title
              );
            "
          >
            Learn More
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="(galleryImages && galleryImages.length > 0) || hasGalleryShortcode"
      id="gallery"
    >
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <p
            class="rentpress-inherited-font-family secondary--text text-center text-subtitle-1"
          >
            Take A Look Around
          </p>
          <h3 class="rentpress-inherited-font-family text-center text-h5">
            {{ property.property_name }} Photos
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-1">
        <v-col
          v-for="(galleryImage, imageKey) in galleryImages"
          :key="'galleryImage' + imageKey"
          class="d-none d-sm-flex child-flex pa-1"
          cols="auto"
          sm="4"
          md="3"
          lg="3"
          xl="2"
        >
          <v-img
            min-height="150px"
            max-height="400px"
            min-width="150px"
            :src="
              galleryImage.sizes.medium
                ? galleryImage.sizes.medium.url
                : galleryImage.url
            "
            aspect-ratio="1"
            @click="viewImageFullscreen(imageKey)"
          />
        </v-col>
        <v-col
          v-for="(galleryImage, imageKey) in galleryImages"
          :key="imageKey"
          class="d-flex d-sm-none child-flex pa-1"
          cols="auto"
        >
          <v-img
            min-height="150px"
            max-height="150px"
            min-width="150px"
            :src="
              galleryImage.sizes.thumbnail
                ? galleryImage.sizes.thumbnail.url
                : galleryImage.url
            "
            aspect-ratio="1"
            @click="viewImageFullscreen(imageKey)"
          />
        </v-col>
      </v-row>
      <v-overlay
        :z-index="zIndexGallery"
        :value="galleryOverlay"
        opacity="0.85"
        v-touch="{
          left: () => gallerySwipe('Left'),
          right: () => gallerySwipe('Right'),
          up: () => gallerySwipe('Up'),
          down: () => gallerySwipe('Down')
        }"
      >
        <v-carousel v-model="selectedGalleryImage" height="100vh">
          <v-carousel-item
            v-for="(galleryImage, galleryImageKey) in galleryImages"
            :key="galleryImageKey"
          >
            <v-sheet
              color="transparent"
              height="100vh"
              width="100vw"
              tile
              @click.self="galleryOverlay = false"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
                @click.self="galleryOverlay = false"
              >
                <v-img
                  max-height="100vh"
                  :height="galleryImage.height"
                  max-width="100vw"
                  :src="galleryImage.url"
                  contain
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          @click="galleryOverlay = false"
          fab
          top
          right
          large
          absolute
          rounded
          class="mt-12"
          v-bind:style="propertyPrimaryStyle"
        >
          <v-icon x-large color="black">mdi-close</v-icon>
        </v-btn>
      </v-overlay>
    </div>
    <div v-if="properties.length > 0">
      <v-row justify="center" class="mt-5">
        <v-col cols="auto">
          <p class="rentpress-inherited-font-family secondary--text">
            Looking For Something Else?
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-5">
        <v-col cols="auto">
          <h3 class="rentpress-inherited-font-family secondary--text text-h5">
            Explore Other Options
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center">
        <div
          class="property-card-wrapper"
          v-for="property in properties"
          :key="property.property_code"
        >
          <div
            class="rentpress-property-card-container rentpress-remove-link-decoration pa-5"
          >
            <property-card
              :property="property"
              :options="options"
              :formatter="formatter"
            />
          </div>
        </div>
      </v-row>
    </div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </div>
</template>

<script>
import propertyCard from "@shared/components/property-card.vue";
import floorplanSearch from "@shared/components/floorplan-search.vue";

export default {
  name: "single-property-page",
  data() {
    return {
      galleryOverlay: false,
      selectedGalleryImage: 0,
      zIndexGallery: 1,
      hasFloorplans: false,
      directionsAddress: this.setAddressForDirections(),
      displayBedroomRange: this.setDisplayBedroomRange(),
      displayPetText: this.setDisplayPetText(),
      displayPhoneNumber: this.setDisplayPhoneNumber(),
      featuredImage: this.setFeaturedImage(),
      featuredImageSrcset: this.setFeaturedImageSrcset(),
      cityPlaceholderImage: this.options.rentpress_default_city_image_section,
      galleryImages: this.setGalleryImages(),
      officeHours: this.setOfficeHours(),
      today: this.setToday(),
      neighborhoodData: this.setNeighborhoodValues(),
      amenitiesList: [],
      featuresList: [],
      propertyGravityForm: "",
      floorplansWithPropertyData: [],
      todaysDate: "",
      scrollOptions: {
        easing: "easeInQuad",
        duration: 1000,
        offset: 0
      },
      propertyPrimaryStyle: {
        backgroundColor: this.options.rentpress_accent_color_section_input,
        borderColor: this.options.rentpress_accent_color_section_input
      },
      jsonld: {
        "@type": "ItemPage",
        primaryImageOfPage: this.property.property_featured_image_src,
        significantLink: this.property.property_availability_url,
        keywords: this.property.property_additional_keywords,
        about: {
          "@type": "Product",
          image: this.property.property_featured_image_src,
          description: this.property.property_description,
          brand: this.options.site_name,
          offers: {
            "@type": "aggregateOffer",
            lowPrice: this.property.property_rent_min,
            highPrice: this.property.property_rent_max,
            priceCurrency: "USD",
            offerCount: this.property.property_floorplan_count
          },
          sku: this.property.property_code,
          name: this.property.property_name,
          sameAs: [
            this.property.property_website,
            this.property.property_facebook_link,
            this.property.property_twitter_link,
            this.property.property_instagram_link
          ]
        },
        breadcrumb: [
          {
            itemListElement: [
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: this.options.site_name + " Home",
                    item: this.options.site_url
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: this.property.property_city,
                    item:
                      this.options.site_url +
                      "/city/" +
                      this.property.property_city
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: this.property.property_name,
                    item: this.property.property_post_link
                  }
                ]
              }
            ]
          }
        ],
        mainEntity: [
          {
            url: this.property.property_post_link,
            telephone: this.property.property_phone_number,
            description: this.property.property_description,
            address: {
              "@type": "PostalAddress",
              streetAddress: this.property.property_address,
              addressLocality: this.property.property_city,
              addressRegion: this.property.property_state,
              postalCode: this.property.property_zip
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: this.property.property_latitude,
              longitude: this.property.property_longitude
            },
            image: [this.property.property_featured_image_src],
            containsPlace: {
              "@type": "Apartment",
              petsAllowed: this.displayPetText
            },
            containedInPlace: {
              "@type": "LocalBusiness",
              pricerange:
                "$" +
                this.property.property_rent_min +
                " - $" +
                this.property.property_rent_max,
              telephone: this.property.property_phone_number,
              image: this.property.property_featured_image_src,
              address: {
                "@type": "PostalAddress",
                streetAddress: this.property.property_address,
                addressLocality: this.property.property_city,
                addressRegion: this.property.property_state,
                postalCode: this.property.property_zip
              },
              openingHours: this.property.property_office_hours, //help
              name: this.property.property_name,
              "@id": this.property.property_post_link
            },
            "@type": "ApartmentComplex",
            name: this.property.property_name,
            tourBookingPage: this.property.property_tour_url,
            hasMap:
              "https://maps.google.com/maps?daddr=" +
              this.property.property_name +
              "+" +
              this.property.property_address +
              "+" +
              this.property.property_city +
              "+" +
              this.property.property_state,
            numberOfAvailableAccommodationUnits: this.property
              .property_available_floorplans,
            amenityFeature: this.property.property_terms,
            "@id": this.property.property_post_link
          }
        ],
        "@context": "http://schema.org"
      }
    };
  },
  props: {
    property: Object,
    properties: Array,
    options: Object,
    city: Object,
    neighborhood: Object,
    hideNeighborhood: Boolean,
    hasGalleryShortcode: Boolean,
    formatter: Intl.NumberFormat,
    useModals: {
      type: Boolean,
      default: true
    },
    showMap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasGravityForm() {
      return this.property.property_contact_type === "3";
    },
    floorplanForm() {
      const contactType = this.property.property_contact_type;
      const formEl = document.getElementById(
        this.property.property_code + "_form_wrapper"
      );
      if (formEl && contactType && contactType !== "Global Setting") {
        return formEl.innerHTML;
      }
      return "";
    },
    contactLink() {
      const contactLink = this.property.property_contact_link;
      const contactType = this.property.property_contact_type;
      const applicationLink = this.options
        .rentpress_application_link_section_url_contact;
      const baseURL =
        contactLink !== "" &&
        contactLink !== null &&
        contactType !== "Global Setting"
          ? contactLink
          : applicationLink;
      return baseURL + "?property_code=" + this.property.property_code;
    },
    tourLink() {
      return (
        this.options.rentpress_application_link_section_url_tour +
        "?property_code=" +
        this.property.property_code
      );
    },
    applyLink() {
      return (
        this.property.property_availability_url ??
        this.options.rentpress_application_link_section_default_application_url
      );
    },
    hasOfficeHours() {
      if (this.officeHours.Monday !== "" || this.officeHours.Tuesday !== "" || this.officeHours.Wednesday !== "" || this.officeHours.Thursday !== "" || this.officeHours.Friday !== "" || this.officeHours.Saturday !== "" || this.officeHours.Sunday !== "") {
        return true;
      }
      return false;
    }
  },
  methods: {
    openGravityFormsModal(id) {
      this.propertyGravityForm = id;
      this.$nextTick(() => {
        const form = this.$refs["gravityform"];
        if (form && form !== undefined && form !== null) {
          const formFields = form.querySelectorAll("input");
          for (var i = formFields.length - 1; i >= 0; i--) {
            let field = formFields[i];
            if (field.value === "floorplan_code") {
              field.value = this.floorplan.floorplan_code;
            }
          }
        }
      });
    },
    setTodaysDate() {
      const today = new Date();
      this.todaysDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    },
    getFloorplansWithPropertyData() {
      var floorplans = this.property.floorplans;
      let property = this.property;
      for (var i = floorplans.length - 1; i >= 0; i--) {
        floorplans[i] = {
          ...property,
          ...floorplans[i]
        };
        delete floorplans[i].floorplans;
      }
      return floorplans;
    },
    setNeighborhoodValues() {
      let neighborhoodData = {};
      if (this.neighborhood.post) {
        neighborhoodData = this.neighborhood;
      } else if (this.city) {
        neighborhoodData = this.city;
      }
      return neighborhoodData;
    },
    setDisplayPhoneNumber() {
      let displayNumber = "";

      if (this.property.property_phone_number) {
        let phoneFormat = this.options.rentpress_format_phone_numbers_section_formatted_number.split(
          ""
        );
        let phone = this.property.property_phone_number.replace(/[^0-9]/g, "");
        let phoneIndex = 0;

        for (
          let formatIndex = 0;
          formatIndex < phoneFormat.length;
          formatIndex++
        ) {
          if (phoneFormat[formatIndex] == "x") {
            displayNumber += phone[phoneIndex];
            phoneIndex++;
          } else {
            displayNumber += phoneFormat[formatIndex];
          }
        }
      }
      return displayNumber;
    },
    setGalleryImages() {
      return this.property.property_gallery_images &&
        this.property.property_gallery_images != ""
        ? JSON.parse(this.property.property_gallery_images)
        : null;
    },
    setOfficeHours() {
      let officeHours = this.property.property_office_hours
        ? JSON.parse(this.property.property_office_hours)
        : "";
      let officeHoursStringsArray = {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: ""
      };
      for (const [day, hour] of Object.entries(officeHours)) {
        let officeHoursString = "";
        if (hour.status === "replace") {
          officeHoursString += hour.append_text != null ? hour.append_text : "";
          officeHoursStringsArray[day] = officeHoursString;
          continue;
        }
        if (hour.status === "appointment") {
          officeHoursString += "By Appointment ";
        }
        if (hour.status === "closed") {
          officeHoursString += "Closed ";
        }
        if (
          hour.open != null &&
          hour.open &&
          hour.status !== "closed" &&
          hour.status !== "appointment"
        ) {
          officeHoursString += hour.open;
          if (hour.close != null && hour.close) {
            officeHoursString += " - " + hour.close;
          }
        }
        if (hour.append === "on" && hour.append_text) {
          officeHoursString += " " + hour.append_text;
        }
        officeHoursStringsArray[day] = officeHoursString;
      }
      return officeHoursStringsArray;
    },
    setToday() {
      var d = new Date();
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      return weekday[d.getDay()];
    },
    gallerySwipe(direction) {
      this.swipeDirection = direction;
      if (direction === "Right") {
        this.selectedGalleryImage = this.selectedGalleryImage++;
      }
      if (direction === "Left") {
        this.selectedGalleryImage = this.selectedGalleryImage--;
      }
      if (direction === "Down") {
        this.galleryOverlay = false;
      }
    },
    viewImageFullscreen(key) {
      this.selectedGalleryImage = key;
      this.galleryOverlay = true;
    },
    navigateToExternalSite(newLocation) {
      // exit out of function if no link, this only happens with banner link, if in the future we switch from the whole banner being a link to a button, this can be removed
      if (!newLocation) {
        return;
      }
      if (newLocation.includes("http")) {
        window.open(newLocation, "_blank");
      } else {
        window.open("http://" + newLocation, "_blank");
      }
    },
    navigateTo(newLocation) {
      window.location = newLocation.includes("http")
        ? newLocation
        : "http://" + newLocation;
    },
    dial(phone_number) {
      window.location = "tel:" + phone_number;
    },
    sendMail(email) {
      window.open(
        "mailto:" +
          email +
          "?subject=Request For Information About " +
          this.property.property_name,
        "_blank"
      );
    },
    setAddressForDirections() {
      return (
        "https://maps.google.com/maps?daddr=" +
        this.property.property_name +
        "+" +
        this.property.property_address +
        "+" +
        this.property.property_city +
        "+" +
        this.property.property_state
      );
    },
    setDisplayBedroomRange() {
      var displayBedroomRange = "";
      var bedTypes = this.property.property_bed_types
        .replace(/[^0-9]/g, "")
        .split("");

      if (bedTypes[0] && bedTypes[0] == 0) {
        displayBedroomRange = "Studio";
      } else if (bedTypes[0]) {
        displayBedroomRange = bedTypes[0] + " Bed";
      }

      if (bedTypes.length > 1) {
        displayBedroomRange += " - " + bedTypes[bedTypes.length - 1] + " Bed";
      }

      return displayBedroomRange;
    },
    setDisplayPetText() {
      var displayPetText = "";

      if (
        this.property.property_terms &&
        this.property.property_terms.toUpperCase().includes("CAT") &&
        this.property.property_terms.toUpperCase().includes("DOG")
      ) {
        displayPetText = "Cat and Dog Friendly";
      } else if (
        this.property.property_terms &&
        this.property.property_terms.toUpperCase().includes("CAT")
      ) {
        displayPetText = "Cat Friendly";
      } else if (
        this.property.property_terms &&
        this.property.property_terms.toUpperCase().includes("DOG")
      ) {
        displayPetText = "Dog Friendly";
      }

      return displayPetText;
    },
    setFeaturedImage() {
      return this.property.property_featured_image_src &&
        this.property.property_featured_image_src != ""
        ? this.property.property_featured_image_src
        : this.options.rentpress_default_property_image_section;
    },
    setFeaturedImageSrcset() {
      return this.property.property_featured_image_srcset &&
        this.property.property_featured_image_srcset != ""
        ? this.property.property_featured_image_srcset
        : this.options.rentpress_default_property_image_section;
    }
  },
  mounted() {
    if (this.property.floorplans && this.property.floorplans.length > 0) {
      this.hasFloorplans = true;
      if (this.property.floorplans[0].property_code == undefined) {
        this.floorplansWithPropertyData = this.getFloorplansWithPropertyData();
      }
    }

    if (this.property.property_use_accent_color === "1") {
      this.propertyPrimaryStyle.backgroundColor = this.property.property_accent_color;
      this.propertyPrimaryStyle.borderColor = this.property.property_accent_color;
    }

    var faList = [];
    if (this.property.property_features) {
      faList = [...JSON.parse(this.property.property_features)];
    }
    for (let i = 0; i < faList.length; i++) {
      this.featuresList.push(faList[i]);
    }

    var amList = [];
    if (this.property.property_community_amenities) {
      amList = [...JSON.parse(this.property.property_community_amenities)];
    }
    for (let i = 0; i < amList.length; i++) {
      this.amenitiesList.push(amList[i]);
    }
  },
  components: { floorplanSearch, propertyCard }
};
</script>

<style>
.rentpress-shortcode-single-property-wrapper .pre-formatted {
  white-space: pre-wrap !important;
}

.rentpress-property-card-container {
  display: flex;
  justify-content: center;
}

.rentpress-property-amenities-list {
  columns: 3;
  width: 100%;
}
#amenities {
  width: 100%;
}
</style>
