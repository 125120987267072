<template>
  <div
    class="
      rentpress-shortcode-single-floorplan-information-wrapper
      rentpress-inherited-font-family
    "
  >
    <v-banner
      v-if="floorplan.floorplan_specials_message"
      elevation="10"
      :color="primaryColor"
      dark
      class="rentpress-inherited-font-family text-h6 text-center px-5"
      style="z-index: 1"
    >
      <div
        @click="
          navigateToExternalSite(floorplan.floorplan_specials_link);
          sendFloorplanGA('View Special');
        "
        style="height:100%;width100%;"
      >
        <span class="font-weight-black">
          <v-icon class="pb-2">mdi-star</v-icon>
          SPECIAL:
        </span>
        {{ floorplan.floorplan_specials_message }}
      </div>
    </v-banner>
    <v-row no-gutters justify="center" class="pa-3">
      <v-col xl="6" lg="6" md="12" sm="12" cols="auto">
        <v-carousel
          :show-arrows="carouselImages.length != 1"
          :hide-delimiters="carouselImages.length == 1"
          :height="$vuetify.breakpoint.smAndDown ? '30vh' : '60vh'"
          class="rentpress-floorplan-carousel"
        >
          <v-carousel-item
            v-for="(carouselImage, imageIndex) in carouselImages"
            :key="imageIndex"
          >
            <v-img
              :src="carouselImage.src"
              :alt="
                carouselImage.alt ||
                  floorplan.floorplan_name + ' image ' + imageIndex
              "
              contain
              max-height="100%"
              :height="carouselImage.imgHeight"
              @click="viewImageFullscreen(imageIndex)"
            >
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <div
          v-if="floorplan.floorplan_matterport_url || engrainMapLink"
          class="rentpress-floorplan-iframe-buttons rentpress-floorplan-under-image-buttons"
        >
          <a
            href="#rentpress-virtual-tour"
            class="floorplan-virtual-tour-button circle-button"
            :style="{ borderColor: primaryColor }"
            v-if="floorplan.floorplan_matterport_url"
          >
            <v-icon medium :color="primaryColor"
              >mdi-play-circle-outline</v-icon
            >
            <p :style="{ color: primaryColor }">Virtual Tour</p>
          </a>
          <a
            href="#sightmap"
            class="floorplan-show-map-button circle-button"
            :style="{ borderColor: primaryColor }"
            v-if="engrainMapLink"
          >
            <v-icon medium :color="primaryColor">mdi-map-marker</v-icon>
            <p :style="{ color: primaryColor }">Show Map</p>
          </a>
        </div>
      </v-col>
      <v-overlay
        z-index="1000000"
        :value="galleryOverlay"
        opacity="0.85"
        v-touch="{
          left: () => gallerySwipe('Left'),
          right: () => gallerySwipe('Right'),
          up: () => gallerySwipe('Up'),
          down: () => gallerySwipe('Down')
        }"
      >
        <v-carousel v-model="selectedGalleryImage" height="100vh">
          <v-carousel-item
            v-for="(galleryImage, galleryImageKey) in carouselImages"
            :key="galleryImageKey"
          >
            <v-sheet
              color="transparent"
              height="100vh"
              width="100vw"
              tile
              @click.self="galleryOverlay = false"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
                @click.self="galleryOverlay = false"
              >
                <v-img
                  max-height="100vh"
                  :height="galleryImage.height"
                  max-width="100vw"
                  :src="galleryImage.src"
                  contain
                />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          @click="galleryOverlay = false"
          fab
          top
          right
          large
          absolute
          rounded
          class="primary mt-12"
        >
          <v-icon x-large color="black">mdi-close</v-icon>
        </v-btn>
      </v-overlay>
      <v-col xl="6" lg="6" md="12" sm="12" cols="auto">
        <v-card tile flat class="pl-5">
          <div class="rentpress-floorplan-information-top-wrapper">
            <div class="rentpress-floorplan-information-title-price-wrapper">
              <v-card-title
                v-if="useFPName"
                class="rentpress-inherited-font-family text-h3 secondary--text pl-0 rentpress-single-floorplan-title"
              >
                {{ floorplan.floorplan_name }}
              </v-card-title>
              <v-card-text
                class="rentpress-inherited-font-family font-weight-bold text-h5 pl-0"
              >
                <span
                  v-if="
                    floorplan.floorplan_rent_type_selection ==
                      'Minimum - Maximum' &&
                      floorplan.floorplan_rent_min &&
                      floorplan.floorplan_rent_max &&
                      floorplan.floorplan_rent_min !=
                        floorplan.floorplan_rent_max
                  "
                >
                  {{ formatter.format(floorplan.floorplan_rent_min) }} -
                  {{ formatter.format(floorplan.floorplan_rent_max) }}
                </span>
                <span
                  v-else-if="
                    floorplan.floorplan_rent_type_selection_cost &&
                      floorplan.floorplan_rent_type_selection_cost > 100
                  "
                >
                  Starting at
                  {{
                    this.formatter.format(
                      floorplan.floorplan_rent_type_selection_cost
                    )
                  }}
                </span>
              </v-card-text>
            </div>
            <div
              v-if="floorplan.floorplan_matterport_url || engrainMapLink"
              class="rentpress-floorplan-iframe-buttons rentpress-floorplan-title-buttons"
            >
              <a
                href="#rentpress-virtual-tour"
                class="floorplan-virtual-tour-button circle-button"
                :style="{ borderColor: primaryColor }"
                v-if="floorplan.floorplan_matterport_url"
              >
                <v-icon medium :color="primaryColor"
                  >mdi-play-circle-outline</v-icon
                >
                <p :style="{ color: primaryColor }">Virtual Tour</p>
              </a>
              <a
                href="#sightmap"
                class="floorplan-show-map-button circle-button"
                :style="{ borderColor: primaryColor }"
                v-if="engrainMapLink"
              >
                <v-icon medium :color="primaryColor">mdi-map-marker</v-icon>
                <p :style="{ color: primaryColor }">Show Map</p>
              </a>
            </div>
          </div>

          <v-card-title
            v-if="!useFPName"
            class="rentpress-inherited-font-family text-h3 secondary--text pl-0"
          >
            {{ displayBedroomsText }}
          </v-card-title>
          <v-card-subtitle
            v-if="useFPName"
            class="rentpress-inherited-font-family text-h5 pb-1 pt-2 pl-0 rentpress-single-floorplan-main-info-block"
          >
            <span
              v-if="displayBedroomsText"
              class="rentpress-floorplan-bedroom-text"
            >
              {{ displayBedroomsText }}
            </span>
            <span v-if="sqFtText" class="rentpress-floorplan-sqft">
              <span class="rentpress-floorplan-information-dot-sep"> • </span
              >{{ sqFtText }}
            </span>
            <span
              v-if="displayDepositText"
              class="rentpress-floorplan-deposit-range"
            >
              <span class="rentpress-floorplan-information-dot-sep"> • </span
              >{{ displayDepositText }} Deposit
            </span>
          </v-card-subtitle>
          <div v-if="floorplan.units.length > 0">
            <v-card-text
              class="
                rentpress-inherited-font-family
                rentpress-floorplan-single-information-subtitle
                text-h6
                font-weight-bold
                pl-0
              "
            >
              Choose An Apartment
            </v-card-text>
            <div class="rentpress-units-selection-wrapper">
              <div v-if="floorplan.units.length > 2">
                <v-icon
                  x-large
                  :color="primaryColor"
                  @click="scrollUnitSlider('left')"
                  >mdi-chevron-left</v-icon
                >
              </div>
              <div class="rentpress-units-selection-container" ref="unitSlider">
                <div
                  v-for="unit in sortedUnits"
                  v-bind:key="unit.unit_code"
                  style="
                    display: inline-block;
                    padding-right: 8px;
                    padding-left: 0px;
                    padding-top: 8px;
                    padding-bottom: 16px;
                    width: 260px;
                  "
                >
                  <div v-on:click="selectThisUnit(unit)">
                    <unit-card
                      v-if="unit"
                      :unit="unit"
                      :options="options"
                      :formatter="formatter"
                      :primaryColor="primaryColor"
                      :disabledPrice="
                        floorplan.floorplan_rent_type_selection == 'Disabled'
                      "
                      :isActive="isActiveUnit(unit.unit_code)"
                    />
                  </div>
                </div>
              </div>
              <div v-if="floorplan.units.length > 2">
                <v-icon
                  x-large
                  :color="primaryColor"
                  @click="scrollUnitSlider('right')"
                  >mdi-chevron-right</v-icon
                >
              </div>
            </div>
          </div>
          <div v-else class="rentpress-inherited-font-family">
            <h4>No Apartments Available</h4>
            <p>
              This is one of our most popular layouts. No apartments are
              currently available.
            </p>
          </div>

          <div
            v-if="selectedUnitFeatures.length"
            class="rentpress-units-feature-container"
          >
            <v-card-text
              class="rentpress-inherited-font-family text-h6 font-weight-bold"
              >Special Features
            </v-card-text>
            <div id="rentpress-unit-features-list">
              <div
                v-for="feature in selectedUnitFeatures"
                :key="feature.Rank"
                class="rentpress-unit-features-list-item"
              >
                <v-icon :color="primaryColor">mdi-chevron-right</v-icon>
                {{ feature.Title }}
              </div>
            </div>
          </div>

          <div
            v-if="selectedUnitTerms.length && propertyHasLeaseTermsSelected"
            class="rentpress-units-term-container"
          >
            <v-card-text
              class="rentpress-inherited-font-family text-h6 font-weight-bold"
              >Available Lease Terms for {{ selectedUnit.unit_name }}
            </v-card-text>
            <v-row>
              <v-col xl="5" lg="6" md="5" sm="7" cols="12">
                <v-menu
                  v-model="termRentDisplay"
                  :close-on-content-click="true"
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="rentpress-inherited-font-family">
                      <v-select
                        solo
                        :label="
                          selectedLeaseTerm
                            ? selectedLeaseTerm +
                              ' Months: ' +
                              formatter.format(selectedLeaseRent)
                            : 'Show Terms'
                        "
                        readonly
                      ></v-select>
                    </div>
                  </template>
                  <v-list
                    id="rentpress-units-term-menu-list"
                    class="rentpress-inherited-font-family"
                    style="overflow-y: auto; max-height: 60vh"
                  >
                    <v-list-item>
                      <v-row justify="end" class="font-weight-bold">
                        <v-col cols="5"> Lease Term </v-col>
                        <v-col cols="7"> Price Per Month </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item
                      v-for="(term, termIndex) in selectedUnitTerms"
                      v-bind:key="termIndex"
                      :value="term.Rent"
                      :class="
                        isBestLeaseValue(term)
                          ? 'font-weight-bold term-list-item term-list-item-best'
                          : 'term-list-item'
                      "
                    >
                      <v-row
                        @click="setDesiredLeaseTerm(term)"
                        style="cursor: pointer"
                      >
                        <v-col cols="5"> {{ term.Term }} Months </v-col>
                        <v-col cols="7">
                          {{ formatter.format(term.Rent) }}
                          <span
                            v-if="isBestLeaseValue(term)"
                            style="white-space: nowrap"
                          >
                            (Best Value)</span
                          >
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <v-row justify="start" class="pt-3 rentpress-single-floorplan-main-info-block-cta-wrapper">
            <v-col
              v-if="options.rentpress_application_link_section_url_tour != ''"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="4"
            >
              <v-btn
                :color="primaryColor"
                x-large
                block
                @click="
                  navigateTo(tourLink);
                  sendFloorplanGA('Schedule Tour');
                "
                class="rentpress-application-link-section-url-tour-button white--text"
              >
                Schedule Tour
              </v-btn>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4">
              <div
                v-if="floorplan.floorplan_parent_property_contact_type == '3'"
              >
                <v-btn
                  block
                  :color="primaryColor"
                  class="rentpress-inherited-font-family"
                  x-large
                  outlined
                  @click="
                    sendFloorplanGA('Request Info');
                    openGravityFormsModal(floorplan.floorplan_post_id);
                  "
                >
                  Request Info
                </v-btn>
                <v-dialog
                  v-model="propertyGravityForm"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable
                >
                  <v-card tile>
                    <v-btn
                      color="#303030"
                      fab
                      dark
                      small
                      absolute
                      top
                      right
                      @click="propertyGravityForm = ''"
                      class="mt-6 mr-n3"
                      elevation="10"
                      style=""
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div
                      class="floorplan-form-pop-up"
                      v-html="floorplanForm"
                      ref="gravityform"
                    ></div>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else>
                <v-btn
                  block
                  :color="primaryColor"
                  class="rentpress-inherited-font-family"
                  x-large
                  :outlined="
                    options.rentpress_application_link_section_url_tour != ''
                  "
                  @click="
                    sendFloorplanGA('Request Info');
                    navigateTo(contactLink);
                  "
                >
                  Request Info
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4">
              <v-btn
                v-if="hasUnits && applyLink"
                :color="primaryColor"
                class="rentpress-inherited-font-family"
                block
                x-large
                outlined
                @click="
                  navigateToExternalSite(applyLink);
                  sendFloorplanGA('Apply Now');
                "
              >
                Apply Now
              </v-btn>
              <v-btn
                v-if="
                  !hasUnits &&
                    options.rentpress_application_link_section_url_waitlist !=
                      ''
                "
                :color="primaryColor"
                class="rentpress-inherited-font-family white--text"
                x-large
                block
                outlined
                @click="
                  navigateTo(waitlistLink);
                  sendFloorplanGA('Join Waitlist');
                "
              >
                Join Our Waitlist
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="rentpress-single-floorplan-main-info-block-social-wrapper">
            <v-col cols="auto">
              <p
                class="
                  rentpress-inherited-font-family
                  font-weight-bold
                  secondary--text
                  pt-1
                "
              >
                SHARE
              </p>
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                :href="twitterLink"
                @click="sendFloorplanGA('Social - Share - Twitter')"
                target="_blank"
              >
                <v-icon class="white--text secondary">mdi-twitter</v-icon>
              </v-btn>
              <v-btn
                icon
                :href="facebookLink"
                @click="sendFloorplanGA('Social - Share - Facebook')"
                target="_blank"
              >
                <v-icon class="white--text secondary">mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                icon
                :href="emailLink"
                @click="sendFloorplanGA('Social - Share - Email')"
                target="_blank"
              >
                <v-icon class="white--text secondary">mdi-email</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <script v-html="jsonld" type="application/ld+json"></script>
  </div>
</template>

<script>
import unitCard from "./unit-card.vue";

export default {
  name: "single-floorplan-information",
  data: function() {
    return {
      hasUnits: false,
      useFPName: true,
      hasSelectedUnit: false,
      propertyHasLeaseTermsSelected: false,
      selectedUnit: {},
      selectedUnitTerms: [],
      selectedUnitFeatures: [],
      selectedLeaseTerm: "",
      selectedLeaseRent: "",
      bestLeaseValue: "",
      image: "",
      carouselImages: [],
      sqFtText: "",
      twitterLink: "",
      facebookLink: "",
      emailLink: "",
      propertyGravityForm: "",
      formInputs: "",
      termRentDisplay: false,
      displayBedroomsText: this.getTextForBedroomsDisplay(),
      primaryColor: this.options.rentpress_accent_color_section_input,
      selectedGalleryImage: 0,
      galleryOverlay: false,
      secondary: this.options.rentpress_secondary_accent_color_section_input,
      unitSliderScrollAmount: 0,
      jsonld: {
        // single floor plan schema
        "@type": "ItemPage",
        primaryImageOfPage: this.floorplan.floorplan_image,
        significantLink: this.floorplan.applyLink,
        about: {
          "@type": "Product",
          name: this.floorplan.floorplan_name,
          image: this.floorplan.floorplan_image,
          description: this.floorplan.floorplan_description,
          brand: this.options.site_name,
          offers: {
            "@type": "aggregateOffer",
            lowPrice: this.floorplan.floorplan_rent_min,
            highPrice: this.floorplan.floorplan_rent_max,
            priceCurrency: "USD",
            offerCount: this.floorplan.floorplan_units_available
          },
          sku: this.floorplan.floorplan_code
        },
        breadcrumb: [
          {
            itemListElement: [
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: this.options.site_name + " Home",
                    item: this.options.site_url
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    // name: this.property.property_name,
                    item: this.floorplan.floorplan_parent_property_post_link
                  }
                ]
              }
            ]
          }
        ],
        mainEntity: [
          {
            "@type": "FloorPlan",
            image: this.floorplan.floorplan_image,
            description: this.floorplan.floorplan_description,
            name: this.floorplan.floorplan_name,
            url: this.floorplan.floorplan_post_link,
            floorsize: this.floorplan.floorplan_sqft_min,
            // isPlanForApartment: this.floorplan.floorplan_parent_property_name,
            numberOfBedrooms: this.floorplan.floorplan_bedrooms,
            numberOfBathroomsTotal: this.floorplan.floorplan_bathrooms,
            petsAllowed: this.displayPetText
          },
          {
            containedInPlace: {
              "@type": "LocalBusiness",
              // name: this.floorplan.floorplan_parent_property_name,
              // description: this.floorplan.property_description,
              priceRange: "",
              // telephone: this.floorplan.property_phone_number,
              image: this.floorplan.floorplan_image,
              address: {
                "@type": "PostalAddress"
                // streetAddress: this.floorplan.property_address,
                // addressLocality: this.floorplan.property_city,
                // addressRegion: this.floorplan.property_state,
                // postalCode: this.floorplan.property_zip
              },
              geo: {
                "@type": "GeoCoordinates"
                // latitude: this.floorplan.property_latitude,
                // longitude: this.floorplan.property_longitude
              },
              "@id": this.floorplan.floorplan_parent_property_post_link
            }
          }
        ],
        "@context": "https://schema.org"
      }
    };
  },
  props: {
    floorplan: Object,
    options: Object,
    formatter: Intl.NumberFormat,
    shortcode: {
      type: String,
      default: ""
    }
  },
  computed: {
    engrainMapLink() {
      const addOnFields = JSON.parse(
        this.floorplan.floorplan_parent_property_add_on_fields
      );
      if (addOnFields) {
        return typeof addOnFields.property_engrain_map !== "undefined"
          ? addOnFields.property_engrain_map
          : "";
      }
      return "";
    },
    floorplanForm() {
      let contact_type = this.floorplan.floorplan_parent_property_contact_type;
      const formEl = document.getElementById(
        this.floorplan.floorplan_parent_property_code + "_form_wrapper"
      );
      if (formEl && contact_type && contact_type != "Global Setting") {
        return formEl.innerHTML;
      }
      return "";
    },
    displayDepositText() {
      let depositMin = Number(this.floorplan.floorplan_deposit_min);
      let depositMax = Number(this.floorplan.floorplan_deposit_max);
      let globalDepositSetting = this.options
        .rentpress_deposit_settings_section_deposit_settings;
      const propDepositSetting = Number(
        this.floorplan.floorplan_parent_property_deposit_setting
      );

      switch (propDepositSetting) {
        case 1:
          // Global Setting
          switch (globalDepositSetting) {
            case "Always Show":
              // dont do anything
              break;
            case "Exclude $0 Deposits":
              if (depositMin === 0) {
                depositMin = null;
              }
              if (depositMax === 0) {
                depositMax = null;
              }
              break;
            case "Show None":
              depositMin = null;
              depositMax = null;
              break;

            default:
          }
          break;
        case 2:
          // Always Show
          break;
        case 3:
          // Exclude $0 Deposits
          if (depositMin === 0) {
            depositMin = null;
          }
          if (depositMax === 0) {
            depositMax = null;
          }
          break;

        case 4:
          // Show None
          depositMin = null;
          depositMax = null;
          break;

        default:
          switch (globalDepositSetting) {
            case "Always Show":
              // dont do anything
              break;
            case "Exclude $0 Deposits":
              if (depositMin === 0) {
                depositMin = null;
              }
              if (depositMax === 0) {
                depositMax = null;
              }
              break;
            case "Show None":
              depositMin = null;
              depositMax = null;
              break;

            default:
          }
          break;
      }

      var depositText = "";
      if (depositMin !== null || depositMax !== null) {
        depositText += "$";
        if (depositMin === depositMax) {
          depositText += depositMax;
          return depositText;
        }
        if (depositMin !== null && depositMax !== null) {
          depositText += depositMin + " - $" + depositMax;
          return depositText;
        }
        if (depositMin !== null) {
          depositText += depositMin;
          return depositText;
        }
        if (depositMax !== null) {
          depositText += depositMax;
          return depositText;
        }
      }
      return depositText;
    },
    sortedUnits() {
      var units = this.floorplan.units;
      units.sort(function(a, b) {
        let aStamp = new Date(a.unit_available_on).getTime();
        let bStamp = new Date(b.unit_available_on).getTime();
        return aStamp > bStamp ? 1 : -1;
      });
      return units;
    },
    unitParamString() {
      let paramString = "";
      if (this.hasSelectedUnit && this.selectedUnit.unit_code) {
        paramString = "&unit_code=" + this.selectedUnit.unit_code;
        paramString = this.selectedLeaseTerm
          ? paramString + "&leaseTerm=" + this.selectedLeaseTerm
          : paramString;
      }
      return paramString;
    },
    applyLink() {
      if (this.hasSelectedUnit && this.selectedUnit.unit_availability_url) {
        return this.selectedUnit.unit_availability_url;
      } else if (this.floorplan.floorplan_availability_url) {
        return this.floorplan.floorplan_availability_url;
      } else {
        return this.options
          .rentpress_application_link_section_default_application_url;
      }
    },
    contactLink() {
      const baseURL =
        this.floorplan.floorplan_parent_property_contact_link != "" &&
        this.floorplan.floorplan_parent_property_contact_link != null
          ? this.floorplan.floorplan_parent_property_contact_link
          : this.options.rentpress_application_link_section_url_contact;
      return (
        baseURL +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code +
        "&floorplan_bed=" +
        this.floorplan.floorplan_bedrooms +
        this.unitParamString
      );
    },
    tourLink() {
      return (
        this.options.rentpress_application_link_section_url_tour +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code +
        this.unitParamString
      );
    },
    waitlistLink() {
      return (
        this.options.rentpress_application_link_section_url_waitlist +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code +
        this.unitParamString
      );
    }
  },
  methods: {
    openGravityFormsModal(id) {
      this.propertyGravityForm = id;
      this.$nextTick(() => {
        let form = this.$refs["gravityform"];
        if (form && form != undefined && form != null) {
          let formFields = form.querySelectorAll("input");
          for (var i = formFields.length - 1; i >= 0; i--) {
            let field = formFields[i];
            switch (field.value) {
              case "floorplan_code":
                field.value = this.floorplan.floorplan_code;
                break;
            }
          }
        }
      });
    },
    scrollUnitSlider(direction) {
      if (direction === "right") {
        this.unitSliderScrollAmount = this.unitSliderScrollAmount + 260;
      }
      if (direction === "left") {
        this.unitSliderScrollAmount = this.unitSliderScrollAmount - 260;
      }
      this.$refs["unitSlider"].scrollTo({
        top: 0,
        left: this.unitSliderScrollAmount,
        behavior: "smooth"
      });
    },
    getTextForBedroomsDisplay() {
      let displayText =
        this.floorplan.floorplan_bedrooms == "0"
          ? "Studio"
          : this.floorplan.floorplan_bedrooms + " Bed";
      return displayText + " | " + this.floorplan.floorplan_bathrooms + " Bath";
    },
    navigateToExternalSite(newLocation) {
      // exit out of function if no link, this only happens with banner link, if in the future we switch from the whole banner being a link to a button, this can be removed
      if (!newLocation) {
        return;
      }
      if (newLocation.includes("http")) {
        window.open(newLocation, "_blank");
      } else {
        window.open("http://" + newLocation, "_blank");
      }
    },
    navigateTo(newLocation) {
      window.location = newLocation.includes("http")
        ? newLocation
        : "http://" + newLocation;
    },
    sendFloorplanGA(category) {
      let label = this.floorplan.floorplan_name;
      label = this.hasSelectedUnit
        ? label + " - " + this.selectedUnit.unit_name
        : label;
      this.sendGAEvent(category, "click", label);
    },
    selectThisUnit: function(selection) {
      if (this.selectedUnit.unit_code === selection.unit_code) {
        this.selectedUnit = {};
        this.hasSelectedUnit = false;
        this.selectedUnitFeatures = [];
        this.selectedUnitTerms = [];
      } else {
        this.selectedUnit = selection;
        this.unitSelected(selection);
        this.hasSelectedUnit = true;
        this.selectedUnitFeatures =
          JSON.parse(selection.unit_features) !== null
            ? JSON.parse(selection.unit_features)
            : [];
        this.selectedUnitTerms =
          JSON.parse(selection.unit_rent_terms) !== null
            ? JSON.parse(selection.unit_rent_terms)
            : [];
        this.propertyHasLeaseTermsSelected =
          selection.unit_rent_type_selection == "Term Rent";
        this.selectedLeaseTerm = "";
        this.bestLeaseValue = "";
        if (
          this.selectedUnitTerms.length &&
          this.propertyHasLeaseTermsSelected
        ) {
          for (
            let index = this.selectedUnitTerms.length - 1;
            index >= 0;
            index--
          ) {
            if (
              this.formatter.format(this.selectedUnitTerms[index].Rent) ==
              this.formatter.format(
                this.selectedUnit.unit_rent_type_selection_cost
              )
            ) {
              this.bestLeaseValue = this.selectedUnitTerms[index].Term;
              break;
            }
          }
        }
      }
    },
    setDesiredLeaseTerm(term) {
      this.selectedLeaseTerm = term.Term;
      this.selectedLeaseRent = term.Rent;
    },
    isActiveUnit(unit_code) {
      return unit_code == this.selectedUnit.unit_code;
    },
    isBestLeaseValue(term) {
      return term.Term == this.bestLeaseValue;
    },
    setUpCarousel() {
      if (this.floorplan.floorplan_images) {
        const imagesArray = JSON.parse(this.floorplan.floorplan_images);
        if (imagesArray !== null) {
          imagesArray.forEach(image => {
            var thisImage = {
              src: image.url,
              alt: image.alt
            };
            if (image.height) {
              thisImage.imgHeight = image.height + "px";
            }
            this.carouselImages.push(thisImage);
          });
        }
      }
    },
    gallerySwipe(direction) {
      this.swipeDirection = direction;
      if (direction === "Right") {
        this.selectedGalleryImage = this.selectedGalleryImage++;
      }
      if (direction === "Left") {
        this.selectedGalleryImage = this.selectedGalleryImage--;
      }
      if (direction === "Down") {
        this.galleryOverlay = false;
      }
    },
    viewImageFullscreen(key) {
      this.selectedGalleryImage = key;
      this.galleryOverlay = true;
    },
    unitSelected(unit) {
      this.$emit("selectedunit", unit);
    }
  },
  mounted: function() {
    if (
      this.floorplan.floorplan_parent_property_use_accent_color === "1" &&
      this.floorplan.floorplan_parent_property_accent_color &&
      this.floorplan.floorplan_parent_property_accent_color != null
    ) {
      this.primaryColor = this.floorplan.floorplan_parent_property_accent_color;
    }
    if (this.floorplan.floorplan_featured_image) {
      this.carouselImages.push({
        src: this.floorplan.floorplan_featured_image,
        alt: this.floorplan.floorplan_name
      });
      this.setUpCarousel();
    } else if (this.floorplan.floorplan_image) {
      this.carouselImages.push({
        src: this.floorplan.floorplan_image,
        alt: this.floorplan.floorplan_name
      });
      this.setUpCarousel();
    } else if (this.floorplan.floorplan_images) {
      this.setUpCarousel();
    } else {
      this.carouselImages.push({
        src: this.options.rentpress_default_floor_plan_image_section,
        alt: "Placeholder image of " + this.floorplan.floorplan_name
      });
    }

    if (
      this.floorplan.floorplan_sqft_max &&
      this.floorplan.floorplan_sqft_min
    ) {
      this.sqFtText =
        this.floorplan.floorplan_sqft_max != this.floorplan.floorplan_sqft_min
          ? `${this.floorplan.floorplan_sqft_min} - ${this.floorplan.floorplan_sqft_max} Sq. Ft`
          : `${this.floorplan.floorplan_sqft_max} Sq. Ft.`;
    } else if (this.floorplan.floorplan_sqft_max) {
      this.sqFtText = `${this.floorplan.floorplan_sqft_max} Sq. Ft.`;
    } else if (this.floorplan.floorplan_sqft_min) {
      this.sqFtText = `${this.floorplan.floorplan_sqft_min} Sq. Ft.`;
    }

    this.hasUnits =
      this.floorplan.units && this.floorplan.units.length > 0 ? true : false;

    this.propertyHasLeaseTermsSelected =
      this.floorplan.floorplan_rent_type_selection == "Term Rent";

    this.twitterLink =
      "https://twitter.com/share?url=" +
      window.location.href +
      "&amp;text=" +
      this.floorplan.floorplan_name +
      "&amp;hashtags=apartments";
    this.facebookLink =
      "https://www.facebook.com/sharer.php?u=" + window.location.href;
    this.emailLink =
      "mailto:?subject=Check Out This Apartment&body=I liked this apartment " +
      this.floorplan.floorplan_name +
      ". What do you think? " +
      window.location.href;
  },
  components: { unitCard }
};
</script>

<style>
.rentpress-units-selection-container {
  display: flex;
  overflow-x: auto !important;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  width: 90%;
}
#rentpress-units-term-menu-list .term-list-item:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-weight: 900;
}
#rentpress-units-term-menu-list .term-list-item-best {
  color: var(--primary-color) !important;
}
.floorplan-form-pop-up {
  padding: 2%;
}
.floorplan-form-pop-up-close-btn {
  padding-top: 5%;
}
.rentpress-magnify-carousel-image-wrapper {
  display: block;
  position: relative;
  bottom: -30px;
}
#rentpress-unit-features-list {
  columns: 2;
}
.rentpress-floorplan-information-title-price-wrapper {
  min-width: 70%;
  display: inline-block;
}
.rentpress-floorplan-iframe-buttons {
  display: inline-flex;
  align-items: center;
  width: 30%;
  min-width: 220px;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.circle-button {
  border: 3px solid var(--primary-color);
  height: 100px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  justify-content: center;
  margin-right: 5px;
  text-decoration: none;
}
.circle-button .v-icon {
  flex: 0 0 100%;
}
.circle-button p {
  width: 80%;
  font-size: 1.2em;
  line-height: 1.3;
  margin: 0;
}
.rentpress-floorplan-information-top-wrapper {
  display: flex;
}
.rentpress-units-selection-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rentpress-floorplan-under-image-buttons {
  display: none;
}

@media only screen and (max-width: 800px) {
  .rentpress-floorplan-information-title-price-wrapper {
    min-width: 60%;
  }
}

@media only screen and (max-width: 580px) {
  .rentpress-floorplan-title-buttons {
    display: none;
  }
  .rentpress-floorplan-under-image-buttons {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .rentpress-single-floorplan-main-info-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .rentpress-single-floorplan-main-info-block span {
    width: 100%;
  }
  .rentpress-floorplan-information-dot-sep {
    display: none;
  }
  .rentpress-units-selection-container .rentpress-shortcode-unit-card {
    width: 200px !important;
  }
}
</style>
