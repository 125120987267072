<template>
  <div style="width: 100%; height: 80vh;">
    <iframe
      title="rentpress engrain sight map"
      ref="sightmap"
      id="sightmap"
      class="rentpress-engrain-sight-map"
      :src="engrainMapLink"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "sight-map",
  data: function() {
    return {
      sightMap: {}
    };
  },
  watch: {
    units() {
      if (typeof this.sightMap.setUnitNumberMatches === "function") {
        if (this.units.length > 1) {
          this.sightMap.setUnitNumberMatches(this.units, {filterEditing: true});
        }  else {
          this.sightMap.locateUnitByUnitNumber(this.units[0]);
          // console.log("locateUnitByUnitNumber", units);
        }
      }
    }
  },
  props: {
    units: Array,
    link: String,
    options: Object,
    floorplans: Array,
    useModals: Boolean
  },
  methods: {
    findFloorplanById(id) {
      var selectedFloorplan = "";
      if (
        typeof this.floorplans !== "undefined" ? this.floorplans.length : ""
      ) {
        selectedFloorplan = this.floorplans.find(
          element =>
            element.floorplan_name === id || element.floorplan_code === id
        );
      }
      return selectedFloorplan ? selectedFloorplan : false;
    },
    hasJsonStructure(str) {
      if (typeof str !== "string") return false;
      try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === "[object Object]" || type === "[object Array]";
      } catch (err) {
        return false;
      }
    }
  },
  created() {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://sightmap.com/embed/api.js");
    document.head.appendChild(tag);
    tag.onload = () => {
      this.sightMap = window.SightMap;
      this.sightMap.Embed(this.$refs.sightmap);
      let sightMap = this.sightMap;
      let units = this.units;
      this.sightMap.on("ready", function () {
        sightMap.getUnitNumbers(function (unitIds) {
          units = units.filter( ( el ) => unitIds.includes( el ) );
          if (units.length > 1) {
            sightMap.setUnitNumberMatches(units, {filterEditing: true});
          } else {
            sightMap.locateUnitByUnitNumber(units[0]);
          }
          sightMap.disableUI(["unitList", "filters", "unitDetails"]);
        });
      }, sightMap, units);
      this.sightMap.on("metrics.unitMap.unit.click", function (event) {
        let floorplanID = this.hasJsonStructure(event.data.unit.floorPlan.name) ? JSON.parse(event.data.unit.floorPlan.name).provider_id : event.data.unit.floorPlan.name;
        const selectedFloorplan = this.findFloorplanById(floorplanID);
        if (this.$parent.$options._componentTag === "floorplan-search" && selectedFloorplan) {
          if (this.useModals) {
            this.$parent.selectThisFloorplan(selectedFloorplan);
          } else {
            window.location = selectedFloorplan.floorplan_post_link;
          }
        }
      }, this);
    };
  },
  computed: {
    engrainMapLink() {
      if (this.link && this.link != undefined) {
        return (
          this.link +
          "?enable_api=1&origin=" +
          location.protocol +
          "//" +
          location.host
        );
      }
      return "";
    }
  }
};
</script>
<style lang="css">
.rentpress-engrain-sight-map {
  border: 0;
}
</style>
