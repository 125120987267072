<template>
  <div class="rentpress-shortcode-single-floorplan-page-wrapper">
    <single-floorplan-information
      ref="floorplanInformation"
      :floorplan="floorplan"
      :options="options"
      :formatter="formatter"
      :shortcode="shortcode"
      @selectedunit="setSelectedUnit"
    />
    <v-card color="#F9F9F9" outlined>
      <v-container fluid>
        <v-row
          class="text-left rentpress-shortcode-single-floorplan-page-row"
          justify="center"
        >
          <v-col
            v-if="floorplan.floorplan_description"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="auto"
          >
            <v-list-item>
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                    rentpress-single-floorplan-about-title
                  "
                >
                  About This Apartment
                </h3>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <p
                  class="rentpress-inherited-font-family text-wrap text-body-1 rentpress-floorplan-about-section"
                >
                  {{ floorplan.floorplan_description }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-else-if="floorplan.property_description"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="auto"
          >
            <v-list-item>
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                  "
                  v-html="'About Our Community - ' + floorplan.property_name"
                ></h3>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <p
                  v-bind:style="{ maxWidth: aboutTextWidth + '%' }"
                  class="
                    rentpress-floorplan-about-section
                    rentpress-inherited-font-family
                    text-body-1
                  "
                  v-html="floorplan.property_description"
                ></p>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            id="rentpress-amenities"
            v-if="community_amenities.length > 0"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="auto"
            class="rentpress-single-floorplan-amenities-wrapper"
          >
            <v-list-item>
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                    rentpress-single-floorplan-amenities-title
                    pb-2
                  "
                >
                  Community Amenities
                </h3>
              </v-list-item-content>
            </v-list-item>
            <v-row no-gutters justify="space-between">
              <v-col
                xl="12"
                lg="12"
                md="12"
                sm="12"
                cols="auto"
                id="community_amenities"
              >
                <div
                  v-for="(amenity, index) in community_amenities"
                  :key="index"
                  class="rentpress-inherited-font-family text-body-2 pb-2"
                >
                  <v-icon class="primary--text">mdi-chevron-right</v-icon>
                  {{ amenity }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            id="rentpress-features"
            v-if="floorplan_features.length > 0"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="auto"
          >
            <v-list-item>
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                  "
                >
                  Floor Plan Features
                </h3>
              </v-list-item-content>
            </v-list-item>
            <v-row no-gutters justify="space-between">
              <v-col
                xl="12"
                lg="12"
                md="12"
                sm="12"
                cols="auto"
                id="floorplan_features"
              >
                <div
                  v-for="(amenity, index) in floorplan_features"
                  :key="index"
                  class="rentpress-inherited-font-family text-body-2"
                >
                  <v-icon class="primary--text">mdi-chevron-right</v-icon>
                  {{ amenity }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="rentpress-pet-policy-container"
            v-if="floorplan.property_pet_policy"
            xl="8"
            lg="8"
            md="12"
            sm="12"
            cols="auto"
          >
            <v-list-item>
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                  "
                  v-html="floorplan.property_name + ' Pet Policy'"
                ></h3>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="
                    rentpress-inherited-font-family
                    text-wrap text-body-1
                    pre-formatted
                  "
                  v-html="floorplan.property_pet_policy"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="hasOfficeHours"
            class="rentpress-office-hours-container"
            xl="4"
            lg="4"
            md="12"
            sm="12"
            cols="auto"
          >
            <v-list-item class="rentpress-office-hours-title">
              <v-list-item-content>
                <h3
                  class="
                    rentpress-inherited-font-family
                    text-h5
                    secondary--text
                    rentpress-single-floorplan-hours-title
                  "
                >
                  Office Hours
                </h3>
              </v-list-item-content>
            </v-list-item>
            <ul class="rentpress-office-hours-list">
              <li v-for="(officeHour, day) in officeHours" :key="day">
                <span
                  v-if="officeHour"
                  class="rentpress-inherited-font-family text-body-2"
                  :class="today == day ? 'font-weight-black' : ''"
                >
                  <v-icon class="primary--text">mdi-chevron-right</v-icon>
                  {{ day }}: <span v-html="officeHour"></span>
                </span>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div v-if="floorplan.floorplan_matterport_url" id="rentpress-virtual-tour">
      <v-row justify="center">
        <v-col cols="auto">
          <h3 class="rentpress-inherited-font-family text-h4 secondary--text">
            TAKE A LOOK AROUND
          </h3>
        </v-col>
      </v-row>
      <v-row style="height: 90vh">
        <v-col style="height: 90vh">
          <iframe
            :src="floorplan.floorplan_matterport_url"
            width="100%"
            height="100%"
            frameborder="0"
            title="3D floorplan"
          ></iframe>
        </v-col>
      </v-row>
      <v-row>
        <v-row justify="center" class="mx-5 py-4">
          <v-col
            v-if="options.rentpress_application_link_section_url_tour != ''"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="7"
          >
            <v-btn
              :color="primaryColor"
              x-large
              block
              @click="navigateTo(tourLink)"
              class="rentpress-application-link-section-url-tour-button white--text"
            >
              Schedule Tour
            </v-btn>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="7">
            <div v-if="floorplan.floorplan_parent_property_contact_type == '3'">
              <v-btn
                block
                :color="primaryColor"
                class="rentpress-inherited-font-family"
                x-large
                :outlined="
                  options.rentpress_application_link_section_url_tour != ''
                "
                @click="doContactAction()"
              >
                Request Info
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                block
                :color="primaryColor"
                class="rentpress-inherited-font-family"
                x-large
                :outlined="
                  options.rentpress_application_link_section_url_tour != ''
                "
                @click="navigateTo(contactLink)"
              >
                Request Info
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="7">
            <v-btn
              v-if="hasUnits && applyLink"
              :color="primaryColor"
              class="rentpress-inherited-font-family"
              block
              x-large
              outlined
              @click="navigateToExternalSite(applyLink)"
            >
              Apply Now
            </v-btn>
            <v-btn
              v-if="
                !hasUnits &&
                  options.rentpress_application_link_section_url_waitlist != ''
              "
              color="primary"
              class="rentpress-inherited-font-family"
              x-large
              block
              outlined
              @click="navigateTo(waitlistLink)"
            >
              Join Our Waitlist
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </div>

    <div v-if="hasUnits && engrainMapLink">
      <sight-map
        :units="floorplanUnits"
        :options="options"
        :link="engrainMapLink"
        :floorplans="[floorplan]"
      ></sight-map>
    </div>

    <div
      v-if="similarFloorplans.length > 0"
      class="rentpress-similar-floorplans"
    >
      <v-row justify="center">
        <v-col cols="12" class="text-center mt-8 ">
          <h3
            class="rentpress-inherited-font-family text-h4 secondary--text rentpress-similar-floorplans-title rentpress-single-floorplan-more-options-title"
          >
            Explore More Options
          </h3>
        </v-col>
        <v-col
          class="rentpress-similar-floorplans-loop-item"
          v-for="similarFloorplan in similarFloorplans"
          :key="similarFloorplan.floorplan_code"
          cols="10"
          xl="3"
          lg="3"
          md="4"
          sm="8"
        >
          <div v-on:click="navigateToPost(similarFloorplan.floorplan_post_id)">
            <floorplan-card
              :floorplan="similarFloorplan"
              :options="options"
              :formatter="formatter"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </div>
</template>

<script>
import singleFloorplanInformation from "./single-floorplan-information.vue";
import floorplanCard from "./floorplan-card.vue";
import sightMap from "./sight-map.vue";

export default {
  name: "singleFloorplanPage",
  data: function() {
    return {
      hasSelectedUnit: false,
      unitHasApplyLink: false,
      singlePropertySite: false,
      floorplanSearchLink: "floorplans",
      selectedUnit: {},
      amenitiesFirst: [],
      amenitiesSecond: [],
      community_amenities: [],
      floorplan_features: [],
      propDataColumns: 4,
      aboutTextWidth: 100,
      officeHours: this.setOfficeHours(),
      hasOfficeHours: false,
      today: this.setToday(),
      hasUnits: false,
      primaryColor: this.options.rentpress_accent_color_section_input,
      jsonld: {
        // single floor plan schema
        "@type": "ItemPage",
        primaryImageOfPage: this.floorplan.floorplan_image,
        significantLink: this.floorplan.applyLink,
        about: {
          "@type": "Product",
          name: this.floorplan.floorplan_name,
          image: this.floorplan.floorplan_image,
          description: this.floorplan.floorplan_description,
          brand: this.options.site_name,
          offers: {
            "@type": "aggregateOffer",
            lowPrice: this.floorplan.floorplan_rent_min,
            highPrice: this.floorplan.floorplan_rent_max,
            priceCurrency: "USD",
            offerCount: this.floorplan.floorplan_units_available
          },
          sku: this.floorplan.floorplan_code
        },
        breadcrumb: [
          {
            itemListElement: [
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: this.options.site_name + " Home",
                    item: this.options.site_url
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    // name: this.property.property_city,
                    item: this.options.site_url + "/city/"
                    // this.property.property_city
                  },
                  {
                    "@type": "ListItem",
                    position: 3
                    // name: this.property.property_name,
                    // item: this.property.property_post_link
                  }
                ]
              }
            ]
          }
        ],
        mainEntity: [
          {
            "@type": "FloorPlan",
            image: this.floorplan.floorplan_image,
            description: this.floorplan.floorplan_description,
            name: this.floorplan.floorplan_name,
            url: this.floorplan.floorplan_post_link,
            floorsize: this.floorplan.floorplan_sqft_min,
            isPlanForApartment: this.floorplan.floorplan_parent_property_name,
            numberOfBedrooms: this.floorplan.floorplan_bedrooms,
            numberOfBathroomsTotal: this.floorplan.floorplan_bathrooms,
            petsAllowed: this.displayPetText
          },
          {
            containedInPlace: {
              "@type": "LocalBusiness",
              name: this.floorplan.floorplan_parent_property_name,
              description: this.floorplan.property_description,
              priceRange: "",
              telephone: this.floorplan.property_phone_number,
              image: this.floorplan.floorplan_image,
              address: {
                "@type": "PostalAddress",
                streetAddress: this.floorplan.property_address,
                addressLocality: this.floorplan.property_city,
                addressRegion: this.floorplan.property_state,
                postalCode: this.floorplan.property_zip
              },
              geo: {
                "@type": "GeoCoordinates",
                latitude: this.floorplan.property_latitude,
                longitude: this.floorplan.property_longitude
              },
              "@id": this.floorplan.property_post_link
            }
          }
        ],
        "@context": "https://schema.org"
      }
    };
  },
  props: {
    floorplan: Object,
    similarFloorplans: Array,
    options: Object,
    formatter: Intl.NumberFormat,
    shortcode: String
  },
  computed: {
    engrainMapLink() {
      const addOnFields = JSON.parse(
        this.floorplan.floorplan_parent_property_add_on_fields
      );
      if (addOnFields) {
        return typeof addOnFields.property_engrain_map !== "undefined"
          ? addOnFields.property_engrain_map
          : "";
      }
      return "";
    },
    applyLink() {
      if (this.floorplan.floorplan_availability_url) {
        return this.floorplan.floorplan_availability_url;
      } else {
        return this.options
          .rentpress_application_link_section_default_application_url;
      }
    },
    contactLink() {
      const contactLink = this.floorplan.floorplan_parent_property_contact_link;
      const baseURL =
        contactLink !== "" && contactLink !== null
          ? contactLink
          : this.options.rentpress_application_link_section_url_contact;
      return (
        baseURL +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code +
        "&floorplan_bed=" +
        this.floorplan.floorplan_bedrooms +
        this.unitParamString
      );
    },
    tourLink() {
      return (
        this.options.rentpress_application_link_section_url_tour +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code
      );
    },
    waitlistLink() {
      return (
        this.options.rentpress_application_link_section_url_waitlist +
        "?property_code=" +
        this.floorplan.floorplan_parent_property_code +
        "&floorplan_code=" +
        this.floorplan.floorplan_code
      );
    },
    floorplanUnits() {
      var unitCodes = [];
      if (Object.keys(this.selectedUnit).length) {
        unitCodes.push(this.selectedUnit.unit_name);
        return unitCodes;
      }
      if (this.floorplan.units.length) {
        const units = this.floorplan.units;
        for (var i = units.length - 1; i >= 0; i--) {
          if (Array.from(units[i].unit_name)[0] == "0") {
            units[i].unit_name = units[i].unit_name.substring(1);
          }
          unitCodes.push(units[i].unit_name);
        }
      }
      return unitCodes;
    }
  },
  methods: {
    navigateToExternalSite(newLocation) {
      if (newLocation.includes("http")) {
        window.open(newLocation, "_blank");
      } else {
        window.open("http://" + newLocation, "_blank");
      }
    },
    navigateTo(newLocation) {
      window.location = newLocation.includes("http")
        ? newLocation
        : "http://" + newLocation;
    },
    navigateToPost: function(post_id) {
      window.location = "/?page_id=" + post_id;
    },
    doContactAction() {
      this.$refs.floorplanInformation.openGravityFormsModal("1");
    },
    setOfficeHours() {
      let officeHours = this.floorplan.property_office_hours
        ? JSON.parse(this.floorplan.property_office_hours)
        : "";
      let officeHoursStringsArray = {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: ""
      };
      for (const [day, hour] of Object.entries(officeHours)) {
        let officeHoursString = "";
        if (hour.status === "replace") {
          officeHoursString += hour.append_text;
          officeHoursStringsArray[day] = officeHoursString;
          continue;
        }
        if (hour.status === "appointment") {
          officeHoursString += "By Appointment ";
        }
        if (hour.status === "closed") {
          officeHoursString += "Closed ";
        }
        if (
          hour.open != null &&
          hour.open &&
          hour.status !== "closed" &&
          hour.status !== "appointment"
        ) {
          officeHoursString += hour.open;
          if (hour.close != null && hour.close) {
            officeHoursString += " - " + hour.close;
          }
        }
        if (hour.append === "on" && hour.append_text) {
          officeHoursString += " " + hour.append_text;
        }
        officeHoursStringsArray[day] = officeHoursString;
      }
      return officeHoursStringsArray;
    },
    setToday() {
      var d = new Date();
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      return weekday[d.getDay()];
    },
    setSelectedUnit(unit) {
      this.selectedUnit = unit;
    }
  },
  mounted() {
    this.hasUnits = this.floorplan.units && this.floorplan.units.length > 0;

    if (
      this.floorplan.floorplan_parent_property_use_accent_color === "1" &&
      this.floorplan.floorplan_parent_property_accent_color &&
      this.floorplan.floorplan_parent_property_accent_color != null
    ) {
      this.primaryColor = this.floorplan.floorplan_parent_property_accent_color;
    }

    for (var key of Object.keys(this.officeHours)) {
      const hour = this.officeHours[key];
      if (hour.openTime !== null || hour.closeTime !== null) {
        this.hasOfficeHours = true;
        break;
      }
    }

    let propDataCount = 0;
    if (
      this.floorplan.floorplan_description
        ? this.floorplan.floorplan_description
        : this.floorplan.property_description
    ) {
      propDataCount++;
    }
    if (this.officeHours) {
      propDataCount++;
    }
    if (this.floorplan.property_pet_policy) {
      propDataCount++;
    }
    if (propDataCount === 1) {
      this.propDataColumns = 7;
    }
    if (propDataCount === 2) {
      this.propDataColumns = 6;
    }

    if (this.floorplan.property_description.length < 800) {
      const lengthSep = Math.abs(
        this.floorplan.property_description.length / (800 / 50)
      );
      this.aboutTextWidth = 50 + lengthSep;
    }

    if (this.floorplan.floorplan_features) {
      this.floorplan_features = [
        ...JSON.parse(this.floorplan.floorplan_features)
      ];
    }
    if (this.floorplan.property_community_amenities) {
      this.community_amenities = [
        ...JSON.parse(this.floorplan.property_community_amenities)
      ];
      if (this.community_amenities.length) {
        this.community_amenities = this.community_amenities.reduce(function(
          a,
          b
        ) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        },
        []);
      }
    }
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        if (window.location.href.includes("virtual_tour_section=true")) {
          this.$vuetify.goTo("#rentpress-virtual-tour");
        }
      }
    };
  },
  components: {
    singleFloorplanInformation,
    floorplanCard,
    sightMap
  }
};
</script>

<style>
.rentpress-shortcode-single-floorplan-page-wrapper .pre-formatted {
  white-space: pre-wrap !important;
}

.rentpress-shortcode-single-floorplan-page-wrapper #community_amenities,
.rentpress-shortcode-single-floorplan-page-wrapper #floorplan_features {
  columns: 3;
  text-indent: -1.8rem;
  padding-left: 1.8rem;
  column-gap: 1.8rem;
}

.rentpress-office-hours-list {
  list-style-type: none;
  padding: 0;
}

.rentpress-shortcode-single-floorplan-page-row {
  padding: 10%;
}

@media only screen and (max-width: 900px) {
  .rentpress-shortcode-wrapper .rentpress-floorplan-about-section {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .rentpress-shortcode-wrapper .rentpress-floorplan-about-section {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .rentpress-shortcode-single-floorplan-page-wrapper #community_amenities,
  .rentpress-shortcode-single-floorplan-page-wrapper #floorplan_features {
    columns: 2;
  }
}

@media only screen and (max-width: 600px) {
  .rentpress-shortcode-single-floorplan-page-wrapper #community_amenities,
  .rentpress-shortcode-single-floorplan-page-wrapper #floorplan_features {
    columns: 1;
    column-gap: 0px;
  }
}
</style>
