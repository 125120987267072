<template>
  <v-card
    min-height="418"
    ripple
    hover
    outlined
    class="d-flex flex-column rentpress-shortcode-floorplan-card"
    :data-parent-property-code="floorplan.floorplan_parent_property_code"
    v-bind:style="{
      'border-bottom': parentPropertyColor
        ? '10px solid ' + parentPropertyColor
        : ''
    }"
  >
    <v-img
      v-bind:alt="floorplan.floorplan_name"
      max-height="250"
      min-height="250"
      :src="image"
      contain
    >
      <v-banner
        v-if="floorplan.floorplan_specials_message"
        elevation="10"
        icon="mdi-star"
        :color="parentPropertyColor ? parentPropertyColor : 'primary'"
        dark
        class="
          rentpress-floorplan-card-special-banner
          rentpress-inherited-font-family
          text-body-2
        "
      >
        <div
          class="rentpress-floorplan-card-special-banner-text"
          style="max-height: 40px"
        >
          <span class="font-weight-black">SPECIAL:</span>
          {{ floorplan.floorplan_specials_message }}
        </div>
      </v-banner>
    </v-img>
    <v-card-title
      v-if="useFPName"
      v-bind:style="{ color: parentPropertyColor }"
      class="
        text-h6
        font-weight-bold
        rentpress-no-wrap-text
        rentpress-inherited-font-family
        rentpress-floorplan-card-title
      "
    >
      {{ floorplan.floorplan_name }}
    </v-card-title>
    <v-card-title
      v-if="!useFPName"
      class="rentpress-inherited-font-family text-h6"
    >
      {{ displayBedroomsText }}
    </v-card-title>
    <v-card-subtitle class="rentpress-inherited-font-family text-body-1">
      <div v-if="useFPName">
        <span>
          {{ displayBedroomsText }}
        </span>
      </div>
      <div v-if="sqFtText">{{ sqFtText }}</div>
    </v-card-subtitle>
    <v-spacer></v-spacer>
    <v-card-text>
      <v-row justify="space-between" no-gutters>
        <v-col
          cols="auto"
          v-bind:style="{ color: parentPropertyColor }"
          class="
            rentpress-inherited-font-family
            text-body-1
            font-italic
          "
        >
          <div
            v-if="
              floorplan.floorplan_units_available == 0 &&
                options.rentpress_application_link_section_url_waitlist != ''
            "
          >
            Join Waitlist
          </div>
          <div
            v-if="
              floorplan.floorplan_units_available > 0 &&
                options.rentpress_disable_floorplan_availability_counter != '1'
            "
          >
            {{ floorplan.floorplan_units_available }} Available
          </div>
        </v-col>
        <v-col
          v-if="
            floorplan.floorplan_rent_type_selection == 'Minimum - Maximum' &&
              floorplan.floorplan_rent_min &&
              floorplan.floorplan_rent_min > 100 &&
              floorplan.floorplan_rent_max &&
              floorplan.floorplan_rent_max > 100 &&
              floorplan.floorplan_rent_min != floorplan.floorplan_rent_max
          "
          cols="auto"
          class="rentpress-inherited-font-family text-body-1 font-italic"
        >
          {{ formatter.format(floorplan.floorplan_rent_min) }} -
          {{ formatter.format(floorplan.floorplan_rent_max) }}
        </v-col>
        <v-col
          v-else-if="
            floorplan.floorplan_rent_type_selection_cost &&
              floorplan.floorplan_rent_type_selection_cost > 100
          "
          cols="auto"
          class="rentpress-inherited-font-family text-body-1 font-italic"
        >
          Starting at
          {{ formatter.format(floorplan.floorplan_rent_type_selection_cost) }}
        </v-col>
        <v-col
          v-else
          cols="auto"
          class="rentpress-inherited-font-family text-body-1 font-italic"
        >
          {{
            options.rentpress_disable_pricing_section_disable_pricing_message
          }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-tooltip left v-if="floorplan.floorplan_matterport_url">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          small
          class="primary rentpress-floorplan-card-tour-fab"
          v-bind="attrs"
          v-on="on"
          @click="openVirtualTour"
          v-bind:style="{
            'background-color': parentPropertyColor + ' !important'
          }"
        >
          <v-icon>mdi-play-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>Virtual Tour Available</span>
    </v-tooltip>
    <v-dialog
      v-if="useModals && floorplan.floorplan_matterport_url"
      v-model="matterportDialog"
      min-height="95vh"
    >
      <v-card height="90vh" style="overflow-y: auto">
        <v-btn
          color="#3030303"
          fab
          dark
          small
          absolute
          top
          right
          @click="matterportDialog = false"
          class="mt-6 mr-n3"
          elevation="10"
          style=""
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <iframe
          :src="floorplan.floorplan_matterport_url"
          width="100%"
          height="100%"
          frameborder="0"
          title="3D floorplan"
        ></iframe>
      </v-card>
    </v-dialog>
    <script v-html="jsonld" type="application/ld+json"></script>
  </v-card>
</template>

<script>
module.exports = {
  name: "floorplan-card",
  data: function() {
    return {
      useFPName: true,
      availableUnitsCount: 0,
      image: this.options.rentpress_default_floor_plan_image_section,
      sqFtText: "",
      matterportDialog: false,
      matterportVideo: "",
      displayBedroomsText: this.getTextForBedroomsDisplay(),
      jsonld: {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.floorplan.floorplan_name,
        description: this.floorplan.floorplan_description,
        image: this.image,
        brand: this.options.site_name,
        sku: this.floorplan.post_id,
        offers: {
          "@type": "AggregateOffer",
          lowPrice: this.floorplan.floorplan_rent_min,
          highPrice: this.floorplan.floorplan_rent_max,
          priceCurrency: "USD",
          offerCount: this.floorplan.floorplan_units_available
        },
        hasMeasurement: this.floorplan.floorplan_sqft_min,
        isRelatedTo: "",
        about: {
          "@type": "FloorPlan",
          image: this.image,
          name: this.floorplan.floorplan_name,
          url: this.floorplan.floorplan_post_link,
          floorsize: this.floorplan.floorplan_sqft_min,
          isPlanForApartment: this.floorplan.floorplan_parent_property_name,
          numberOfBedrooms: this.floorplan.floorplan_bedrooms,
          numberOfBathroomsTotal: this.floorplan.floorplan_bathrooms,
          numberOfAccommodationUnits: this.floorplan.floorplan_units_available
        }
      }
    };
  },
  props: {
    floorplan: Object,
    options: Object,
    formatter: Intl.NumberFormat,
    useModals: Boolean,
    propColors: Object
  },
  computed: {
    parentPropertyColor() {
      var color = this.options.rentpress_accent_color_section_input;
      if (this.floorplan.floorplan_parent_property_use_accent_color === "1") {
        color = this.floorplan.floorplan_parent_property_accent_color;
      }
      return color;
    }
  },
  methods: {
    getTextForBedroomsDisplay() {
      let displayText =
        this.floorplan.floorplan_bedrooms == "0"
          ? "Studio"
          : this.floorplan.floorplan_bedrooms + " Bed";
      return displayText + " | " + this.floorplan.floorplan_bathrooms + " Bath";
    },
    openVirtualTour(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.useModals) {
        this.matterportVideo = this.floorplan.floorplan_matterport_url;
        this.matterportDialog = true;
      } else {
        this.navigateToVirtualTourSection();
      }
    },
    navigateToVirtualTourSection: function() {
      window.location =
        this.floorplan.floorplan_post_link + "?virtual_tour_section=true";
    }
  },
  mounted() {
    if (this.floorplan.floorplan_featured_image_thumbnail) {
      this.image = this.floorplan.floorplan_featured_image_thumbnail;
    } else if (this.floorplan.floorplan_image) {
      this.image = this.floorplan.floorplan_image;
    } else if (
      this.floorplan.floorplan_images &&
      this.floorplan.floorplan_images[0]
    ) {
      this.image = this.floorplan.floorplan_images[0];
    }

    if (
      this.floorplan.floorplan_sqft_max &&
      this.floorplan.floorplan_sqft_min
    ) {
      this.sqFtText =
        this.floorplan.floorplan_sqft_max != this.floorplan.floorplan_sqft_min
          ? `${this.floorplan.floorplan_sqft_min} - ${this.floorplan.floorplan_sqft_max} Sq. Ft`
          : `${this.floorplan.floorplan_sqft_max} Sq. Ft.`;
    } else if (this.floorplan.floorplan_sqft_max) {
      this.sqFtText = `${this.floorplan.floorplan_sqft_max} Sq. Ft.`;
    } else if (this.floorplan.floorplan_sqft_min) {
      this.sqFtText = `${this.floorplan.floorplan_sqft_min} Sq. Ft.`;
    }
  }
};
</script>
<style lang="css">
.rentpress-floorplan-card-special-banner {
  opacity: 0.95;
}
.rentpress-floorplan-card-special-banner .v-banner__wrapper {
  padding: 4px;
}
.rentpress-floorplan-card-special-banner .v-banner__icon {
  margin: 0px;
}
.rentpress-floorplan-card-tour-fab {
  bottom: 100px;
  right: 20px;
  position: absolute;
}
</style>
