<template>
  <a
    :href="property.property_post_link"
    @click="sendGAEvent('View Property', 'click', property.property_name)"
  >
    <v-card max-width="410" hover :tile="hideImage">
      <v-img
        v-if="!hideImage"
        :src="featuredImage"
        :srcset="featuredImageSrcset"
        height="245px"
        max-width="100%"
        sizes="(max-width: 500px) 500px, 500px"
      >
        <v-banner
          v-if="
            property.property_specials_message &&
              property.property_specials_expiration >= todaysDate
          "
          elevation="10"
          icon="mdi-star"
          color="primary"
          dark
          class="
            rentpress-property-card-special-banner
            rentpress-inherited-font-family
            text-body-2
          "
        >
          <div>
            <span class="font-weight-black">SPECIAL:</span>
            {{ property.property_specials_message }}
          </div>
        </v-banner>
      </v-img>
      <div class="rentpress-property-card-pet-icons-container">
        <v-sheet
          v-if="allowsCats"
          class="rounded-circle"
          elevation="4"
          :height="32"
          :width="32"
          top
          right
        >
          <v-icon class="px-1 py-1" color="black"> mdi-cat </v-icon>
        </v-sheet>
        <v-sheet
          v-if="allowsDogs"
          class="rounded-circle"
          color=""
          elevation="4"
          :height="32"
          :width="32"
          top
          right
        >
          <v-icon class="px-1 py-1" color="black"> mdi-dog </v-icon>
        </v-sheet>
      </div>
      <v-card-title
        primary-title
        class="
          rentpress-inherited-font-family
          text-h6
          font-weight-bold
          rentpress-no-wrap-text
          pb-0
          rentpress-property-card-title
        "
        v-html="property.property_name"
      >
      </v-card-title>
      <v-card-subtitle
        align="left"
        class="rentpress-inherited-font-family text-body-1 mt-n2"
        v-html="subtitleText"
      />
      <v-card-text
        v-if="showPriceMatrix && differentPrices"
        class="rentpress-infocard-matrix-grid-container"
      >
        <div class="rentpress-property-card-matrix-grid">
          <div
            v-for="(availablePrice, bedIndex) in priceMatrix"
            :key="bedIndex"
          >
            <div v-html="availablePrice.name" class="font-weight-black" />
            <div
              v-html="
                availablePrice.price
                  ? formatter.format(availablePrice.price) + '+'
                  : ''
              "
            />
          </div>
        </div>
        <div
          v-if="badPrice"
          v-html="
            options.rentpress_disable_pricing_section_disable_pricing_message
          "
        />
      </v-card-text>
      <v-card-text v-else class="rentpress-inherited-font-family text-body-1">
        <div v-if="displayBedTypes">
          <span class="font-weight-black">{{ displayBedTypes }}</span>
          <span v-if="displayBedTypes != 'Studio'"> Bedroom</span>
        </div>
        <div v-if="displayPrice">
          <span class="" v-html="displayPrice"></span>
        </div>
        <div v-else>
          <span class="font-italic">
            {{
              options.rentpress_disable_pricing_section_disable_pricing_message
            }}
          </span>
        </div>
      </v-card-text>
      <script v-html="jsonld" type="application/ld+json"></script>
    </v-card>
  </a>
</template>
<script>
export default {
  name: "property-card",
  data: function() {
    return {
      bed_types: [],
      priceMatrix: [],
      termsList: [],
      badPrice: true,
      displayBedTypes: "",
      displayPrice: "",
      showPriceMatrix: this.options.show_matrix,
      featuredImage: this.setFeaturedImage(),
      featuredImageSrcset: this.setFeaturedImageSrcset(),
      subtitleText: "",
      allowsDogs: this.checkForValueInTerms("Dog"),
      allowsCats: this.checkForValueInTerms("Cat"),
      differentPrices: false,
      currentPrice: null,
      todaysDate: "",
      jsonld: {
        // do schema here
        "@context": "https://schema.org/",
        "@type": "ApartmentComplex",
        address: {
          "@type": "PostalAddress",
          streetAddress: this.property.property_address,
          addressLocality: this.property.property_city,
          addressRegion: this.property.property_state,
          postalCode: this.property.property_zip
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: this.property.property_latitude,
          longitude: this.property.property_longitude
        },
        telephone: this.property.property_phone_number,
        image: this.property.property_featured_image_src,
        name: this.property.property_name,
        numberOfAvailableAccommodationUnits: this.property
          .property_available_floorplans,
        tourBookingPage: this.property.property_tour_url,
        amenityFeature: this.property.property_terms,
        petsAllowed: this.displayPetText,
        url: this.property.property_post_link,
        sameAs: [
          this.property.property_website,
          this.property.property_facebook_link,
          this.property.property_twitter_link,
          this.property.property_instagram_link
        ]
      }
    };
  },
  props: {
    property: Object,
    options: Object,
    hideImage: {
      type: Boolean,
      default: false
    },
    formatter: Intl.NumberFormat
  },
  methods: {
    navigateToPost: function(post_id) {
      window.location = "/?page_id=" + post_id;
    },
    setFeaturedImage() {
      return this.property.property_featured_image_src &&
        this.property.property_featured_image_src !== ""
        ? this.property.property_featured_image_src
        : this.options.rentpress_default_property_image_section;
    },
    setFeaturedImageSrcset() {
      return this.property.property_featured_image_srcset &&
        this.property.property_featured_image_srcset !== ""
        ? this.property.property_featured_image_srcset
        : this.options.rentpress_default_property_image_section;
    },
    setSubtitle() {
      const subtitleTextString = this.property.property_neighborhood_post_name
        ? this.property.property_neighborhood_post_name + ", "
        : "";

      this.subtitleText =
        subtitleTextString +
        this.property.property_city +
        ", " +
        this.property.property_state;
    },
    checkForValueInTerms(value) {
      if (!this.termsList) {
        this.termsList = [];
      }
      if (this.property.property_terms) {
        this.termsList = JSON.parse(this.property.property_terms);
      }
      if (
        this.termsList.length > 0 &&
        (this.termsList.includes(value) ||
          this.termsList.includes(value + " Friendly"))
      ) {
        return true;
      }
      return false;
    },
    setTodaysDate() {
      const today = new Date();
      this.todaysDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    },
    setDefaultvalues() {
      if (this.property.property_bed_types) {
        this.bed_types = JSON.parse(this.property.property_bed_types).sort();
      }

      if (this.bed_types.length) {
        if (this.bed_types[0] === 0) {
          this.displayBedTypes = "Studio";
        } else {
          this.displayBedTypes = this.bed_types[0];
        }

        if (this.bed_types.length > 1) {
          this.displayBedTypes +=
            " - " + this.bed_types[this.bed_types.length - 1];
        }
      }

      if (this.property.property_availability_matrix) {
        this.priceMatrix = JSON.parse(
          this.property.property_availability_matrix
        );
        Object.values(this.priceMatrix).forEach(bedType => {
          if (bedType.price && bedType.price > 100) {
            this.badPrice = false;
          }
          if (this.currentPrice && this.currentPrice !== bedType.price) {
            this.differentPrices = true;
          } else if (!this.currentPrice) {
            this.currentPrice = bedType.price;
          }
        });
      }

      if (
        this.property.property_rent_type_selection === "Minimum - Maximum" &&
        this.property.property_rent_min &&
        this.property.property_rent_max &&
        this.property.property_rent_min !== this.property.property_rent_max
      ) {
        this.displayPrice =
          "<b>" +
          this.formatter.format(this.property.property_rent_min) +
          " - " +
          this.formatter.format(this.property.property_rent_max) +
          "</b>";
      } else if (
        this.property.property_rent_type_selection_cost &&
        this.property.property_rent_type_selection_cost > 100
      ) {
        this.displayPrice =
          "<b>" +
          this.formatter.format(
            this.property.property_rent_type_selection_cost
          ) +
          "</b> Starting Price";
      }
    }
  },
  watch: {
    property() {
      this.currentPrice = null;
      this.differentPrices = false;
      this.displayPrice = "";
      this.setDefaultvalues();
      this.setSubtitle();
    }
  },
  mounted: function() {
    this.setDefaultvalues();
    this.setSubtitle();
  }
};
</script>

<style lang="css">
.rentpress-property-card-special-banner {
  opacity: 0.95;
}
.v-application .rentpress-property-card-special-banner.primary {
  background-color: var(--primary-color);
}
.rentpress-property-card-special-banner .v-banner__wrapper {
  padding: 4px;
}
.rentpress-property-card-special-banner .v-banner__icon {
  margin: 0px;
}
.rentpress-property-card-pet-icons-container {
  position: absolute;
  margin-top: -16px;
  right: 0.5em;
  display: flex;
  gap: 0.5em;
}
.rentpress-property-card-pet-icons-container .v-icon.v-icon {
  display: inherit;
}
.rentpress-property-card-matrix-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 4rem);
  justify-items: start;
}
.rentpress-map-infocard .v-card {
  box-shadow: none;
}
.rentpress-map-infocard .rentpress-property-card-pet-icons-container {
  display: none;
}
.rentpress-map-infocard div {
  padding: 0;
}
.rentpress-map-infocard .v-card__subtitle {
  padding-bottom: 10px;
}
.rentpress-map-infocard .rentpress-property-card-matrix-grid {
  display: flex;
}
.rentpress-map-infocard .rentpress-property-card-matrix-grid div {
  margin-right: 10px;
}
.rentpress-map-infocard .v-card__title {
  white-space: initial;
  line-height: 1.3em !important;
  margin-bottom: 10px;
  max-width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 400px) {
  .rentpress-map-infocard .rentpress-property-card-matrix-grid {
    display: grid;
  }
}
</style>
