<template>
  <div class="rentpress-shortcode-floorplan-search-wrapper">
    <div :class="!sidebarFilters ? 'rentpress-floorplan-search-container' : ''">
      <div
        v-if="!hideFiltersOption"
        class="hidden-md-and-up my-5 rentpress-floorplan-top-filters-button"
      >
        <v-btn block color="primary" @click="toggleFilters = !toggleFilters">
          <v-icon class="pr-4">mdi-tune</v-icon> Toggle Filters
        </v-btn>
      </div>
      <div
        v-if="!hideFiltersOption && !sidebarFilters && shouldUseTopFilters"
        class="rentpress-top-floorplan-search-filter-wrapper"
      >
        <div class="rentpress-top-floorplan-search-filters">
          <p class="secondary--text font-weight-bold">FILTER</p>
          <div>
            <div>
              <v-select
                solo
                clearable
                multiple
                chips
                deletable-chips
                label="Bedroom Count"
                :items="possibleBedrooms"
                v-model="selectedBedrooms"
                @change="updateParams"
              ></v-select>
            </div>
            <div v-if="priceRanges.length > 0">
              <v-select
                solo
                clearable
                label="Max Price"
                :items="priceRanges"
                v-model="selectedPrice"
                @change="updateParams"
              ></v-select>
            </div>
            <div>
              <v-menu v-model="dateDisplay" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-select solo :label="fromDateDisplay" readonly></v-select>
                  </div>
                </template>
                <v-date-picker
                  v-model="dateSelectorValue"
                  @change="updateParams"
                  @input="dateDisplay = false"
                  color="secondary"
                  header-color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateSelectorValue = null">
                    CLEAR
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>

            <div v-if="floorplanFeatureFilterValues.length > 0">
              <v-select
                solo
                clearable
                multiple
                chips
                deletable-chips
                label="Features"
                :items="floorplanFeatureFilterValues"
                v-model="selectedFeatures"
                @change="updateParams"
              ></v-select>
            </div>

            <div v-if="parentproperties.length > 1 && !hidecommunityfilter">
              <v-select
                solo
                clearable
                multiple
                chips
                deletable-chips
                label="Community"
                :items="possibleProperties"
                v-model="selectedProperties"
                @change="updateParams"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="rentpress-top-floorplan-search-filter-sort">
          <p class="secondary--text font-weight-bold">SORT</p>
          <div>
            <div>
              <v-select
                solo
                label="Select Sort"
                :items="possibleSorts"
                v-model="selectedSort"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          sidebarFilters
            ? 'rentpress-floorplan-sidebar-search-container'
            : 'rentpress-floorplan-card-wrapper'
        "
      >
        <div
          v-if="
            !hideFiltersOption &&
              shouldShowFilters &&
              (sidebarFilters || !shouldUseTopFilters)
          "
          xl="4"
          lg="4"
          md="6"
          sm="12"
          cols="auto"
          background-color="#F9F9F9"
          class="rentpress-floorplan-sidebar"
        >
          <v-row no-gutters justify="center">
            <v-col
              cols="12"
              class="myFont font-weight-bold rentpress-browse-floorplans-input"
              v-if="engrainMapLink"
            >
              <v-btn
                class="rentpress-inherited-font-family"
                block
                large
                color="primary"
                @click="toggleMap = false"
                :outlined="toggleMap"
              >
                Browse Floor Plans
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="myFont font-weight-bold rentpress-browse-map-input"
              v-if="engrainMapLink"
            >
              <v-btn
                color="secondary"
                class="rentpress-inherited-font-family"
                block
                large
                @click="toggleMap = true"
                :outlined="!toggleMap"
              >
                Browse Apartment Map
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
            >
              Bedrooms
            </v-col>
            <v-col xl="10" lg="10" md="8" sm="6" cols="8">
              <v-select
                solo
                clearable
                multiple
                chips
                deletable-chips
                label="Bedroom Count"
                :items="possibleBedrooms"
                v-model="selectedBedrooms"
                @change="updateParams"
              ></v-select>
            </v-col>
            <v-col
              v-if="priceRanges.length > 0"
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
            >
              Max Price / Month
            </v-col>
            <v-col
              xl="10"
              lg="10"
              md="8"
              sm="6"
              cols="8"
              v-if="priceRanges.length > 0"
            >
              <v-select
                solo
                clearable
                label="Max Price"
                :items="priceRanges"
                v-model="selectedPrice"
                @change="updateParams"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
            >
              Move-in Date
            </v-col>
            <v-col xl="10" lg="10" md="8" sm="6" cols="8">
              <v-menu v-model="dateDisplay" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-select solo :label="fromDateDisplay" readonly></v-select>
                  </div>
                </template>
                <v-date-picker
                  v-model="dateSelectorValue"
                  @change="updateParams"
                  @input="dateDisplay = false"
                  color="secondary"
                  header-color="primary"
                  style="width: 100%"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateSelectorValue = null">
                    CLEAR
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
              v-if="parentproperties.length > 1 && !hidecommunityfilter"
            >
              Community
            </v-col>
            <v-col
              xl="10"
              lg="10"
              md="8"
              sm="6"
              cols="8"
              v-if="parentproperties.length - 1 && !hidecommunityfilter"
            >
              <v-checkbox
                clearable
                v-model="selectedProperties"
                v-for="prop in parentproperties"
                :key="prop.property_code"
                :label="prop.property_name"
                :value="prop.property_code"
                :color="
                  prop.property_use_accent_color === '1'
                    ? prop.property_accent_color
                    : ''
                "
              ></v-checkbox>
            </v-col>

            <v-col
              v-if="priceRanges.length > 0"
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
            >
              Features
            </v-col>
            <v-col
              xl="10"
              lg="10"
              md="8"
              sm="6"
              cols="8"
              v-if="floorplanFeatureFilterValues.length > 0"
            >
              <v-select
                solo
                clearable
                multiple
                chips
                deletable-chips
                label="Features"
                :items="floorplanFeatureFilterValues"
                v-model="selectedFeatures"
                @change="updateParams"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col
              cols="12"
              class="myFont text-overline font-weight-bold text-center secondary--text"
            >
              Sort
            </v-col>
            <v-col xl="10" lg="10" md="8" sm="6" cols="8">
              <v-select
                solo
                label="Select Sort"
                :items="possibleSorts"
                v-model="selectedSort"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-row
          justify="center"
          v-if="!toggleMap"
          :class="
            engrainMapLink && !sidebarFilters
              ? 'rentpress-floorplan-search-has-map'
              : ''
          "
        >
          <v-col
            :sm="engrainMapLink && !sidebarFilters ? 6 : 12"
            justify="center"
            v-if="filteredFloorplans.length > 0"
            class="rentpress-floorplan-card-container"
          >
            <div
              v-for="floorplan in filteredFloorplans"
              :key="floorplan.floorplan_code"
              v-resize="onResize"
              cols="auto"
              class="rentpress-remove-link-decoration"
            >
              <a
                :href="useModals ? false : floorplan.floorplan_post_link"
                v-on:click="selectThisFloorplan(floorplan)"
              >
                <floorplan-card
                  :floorplan="floorplan"
                  :options="options"
                  :formatter="formatter"
                  :useModals="useModals"
                  :propColors="propertyColors"
                />
              </a>
            </div>
          </v-col>
          <v-col sm="6" v-else class="rentpress-floorplan-card-container">
            <div cols="auto">
              <div
                class="rentpress-shortcode-floorplan-search-no-floorplans-container"
              >
                <p>No apartments meet your search criteria</p>
                <p>Please try another search or contact us.</p>
              </div>
            </div>
          </v-col>
          <v-col sm="6" v-if="engrainMapLink && !sidebarFilters">
            <sight-map
              :units="filteredFloorplanUnits"
              :options="options"
              :link="engrainMapLink"
              :floorplans="filteredFloorplans"
              :useModals="useModals"
            ></sight-map>
          </v-col>
        </v-row>
        <div
          v-if="toggleMap && engrainMapLink"
          class="rentpress-sightmap-container"
        >
          <sight-map
            :units="filteredFloorplanUnits"
            :options="options"
            :link="engrainMapLink"
            :floorplans="filteredFloorplans"
            :useModals="useModals"
          ></sight-map>
        </div>
      </div>
    </div>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      v-model="dialog"
      min-height="95vh"
    >
      <v-card
        height="90vh"
        style="overflow-y: auto; overflow-x: none;"
        class="pt-8"
      >
        <v-btn
          color="#303030"
          fab
          dark
          small
          absolute
          top
          right
          @click="dialog = false"
          class="mt-6 mr-n3"
          elevation="10"
          style=""
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <single-floorplan-page
          v-if="selectedFloorplan.floorplan_code"
          :floorplan="selectedFloorplan"
          :options="options"
          :similarFloorplans="[]"
          :formatter="formatter"
          class="pt-8 px-3"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import singleFloorplanPage from "./single-floorplan-page.vue";
import floorplanCard from "./floorplan-card.vue";
import sightMap from "./sight-map.vue";

export default {
  name: "floorplan-search",
  data() {
    return {
      toggleFilters: false,
      toggleMap: false,
      windowHorizontalSize: 0,
      selectedFloorplan: {},
      selectedBedrooms: [],
      selectedProperties: [],
      selectedPropertiesCheckbox: [],
      possibleBedrooms: [],
      possibleProperties: [],
      dateDisplay: false,
      dateSelectorValue: null,
      floorplanFeatureFilterValues: [],
      possibleSorts: [
        {
          text: "Rent: Low To High",
          value: "ARENT"
        },
        {
          text: "Rent: High To Low",
          value: "DRENT"
        },
        {
          text: "SqFt: Low To High",
          value: "ASQFT"
        },
        {
          text: "SqFt: High To Low",
          value: "DSQFT"
        },
        {
          text: "Bedrooms",
          value: "BED"
        },
        {
          text: "Availability",
          value: "AVAIL"
        }
      ],
      selectedSort: "AVAIL",
      showModal: false,
      priceRanges: [],
      selectedPrice: "",
      selectedFeatures: [],
      dialog: false
    };
  },
  props: {
    floorplans: Array,
    parentproperties: Array,
    options: Object,
    hidecommunityfilter: {
      type: Boolean,
      default: false
    },
    hideFiltersOption: {
      type: Boolean,
      default: false
    },
    sidebarFilters: {
      type: Boolean,
      default: false
    },
    useModals: {
      type: Boolean,
      default: false
    },
    formatter: Intl.NumberFormat,
    useEngrain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fromDateDisplay() {
      if (this.dateSelectorValue) {
        return this.dateSelectorValue;
      }
      return "Move-In Date";
      // format/do something with date in url?
    },
    shouldShowFilters() {
      return this.toggleFilters || this.windowHorizontalSize >= 960;
    },
    shouldUseTopFilters() {
      if (!this.sidebarFilters && this.windowHorizontalSize < 960) {
        return false;
      }
      return this.windowHorizontalSize >= 960;
    },
    filteredFloorplans() {
      var chosenDateObj = new Date();
      if (this.dateSelectorValue) {
        let dateArray = this.dateSelectorValue.split("-");
        chosenDateObj = new Date(
          dateArray[0],
          parseInt(dateArray[1]) - 1,
          parseInt(dateArray[2]) + 1
        );
      }
      var fps = this.floorplans.filter(floorplan => {
        if (
          this.selectedBedrooms.length > 0 &&
          !this.selectedBedrooms.includes(
            parseInt(floorplan.floorplan_bedrooms)
          )
        ) {
          return false;
        }

        if (this.selectedFeatures.length > 0) {
          if (floorplan.floorplan_features === null) {
            return false;
          }
          let floorplan_features = JSON.parse(floorplan.floorplan_features);
          let floorplan_features_filtered = this.selectedFeatures.some(ai =>
            floorplan_features.includes(ai)
          );
          if (!floorplan_features_filtered) {
            return false;
          }
        }

        if (
          this.selectedProperties.length > 0 &&
          !this.selectedProperties.includes(
            floorplan.floorplan_parent_property_code
          )
        ) {
          return false;
        }

        if (this.selectedPrice && this.selectedPrice != "") {
          let price = parseInt(this.selectedPrice.replace(/[^0-9]/g, ""));
          if (
            price < floorplan.floorplan_rent_type_selection_cost ||
            floorplan.floorplan_rent_type_selection_cost < 100
          ) {
            return false;
          }
        }

        if (this.dateSelectorValue) {
          if (floorplan.units.length == 0) {
            return false;
          }

          for (var i = floorplan.units.length - 1; i >= 0; i--) {
            var availableDate = new Date(floorplan.units[i].unit_ready_date);
            if (chosenDateObj > availableDate) {
              return true;
            }
          }
          return false;
        }

        return true;
      });
      if (this.selectedSort == "ARENT") {
        fps.sort((a, b) => {
          if (a.floorplan_rent_type_selection_cost === null) return 1;
          if (b.floorplan_rent_type_selection_cost === null) return -1;
          return (
            parseInt(a.floorplan_rent_type_selection_cost) -
            parseInt(b.floorplan_rent_type_selection_cost)
          );
        });
      }
      if (this.selectedSort == "DRENT") {
        fps.sort((a, b) => {
          if (a.floorplan_rent_type_selection_cost === null) return 1;
          if (b.floorplan_rent_type_selection_cost === null) return -1;
          return (
            parseInt(b.floorplan_rent_type_selection_cost) -
            parseInt(a.floorplan_rent_type_selection_cost)
          );
        });
      }
      if (this.selectedSort == "ASQFT") {
        fps.sort((a, b) => {
          if (a.floorplan_sqft_min === null) return 1;
          if (b.floorplan_sqft_min === null) return -1;
          return (
            parseInt(a.floorplan_sqft_min) - parseInt(b.floorplan_sqft_min)
          );
        });
      }
      if (this.selectedSort == "DSQFT") {
        fps.sort((a, b) => {
          if (a.floorplan_sqft_min === null) return 1;
          if (b.floorplan_sqft_min === null) return -1;
          return (
            parseInt(b.floorplan_sqft_min) - parseInt(a.floorplan_sqft_min)
          );
        });
      }
      if (this.selectedSort == "BED") {
        fps.sort((a, b) => {
          if (a.floorplan_sqft_min === null) return 1;
          if (b.floorplan_sqft_min === null) return -1;
          return (
            parseInt(b.floorplan_sqft_min) - parseInt(a.floorplan_sqft_min)
          );
        });
        fps.sort((a, b) => {
          if (a.floorplan_bedrooms === null) return 1;
          if (b.floorplan_bedrooms === null) return -1;
          return (
            parseInt(b.floorplan_bedrooms) - parseInt(a.floorplan_bedrooms)
          );
        });
      }
      if (this.selectedSort == "AVAIL") {
        fps.sort((a, b) => {
          if (a.floorplan_rent_type_selection_cost === null) return 1;
          if (b.floorplan_rent_type_selection_cost === null) return -1;
          return (
            parseInt(a.floorplan_rent_type_selection_cost) -
            parseInt(b.floorplan_rent_type_selection_cost)
          );
        });
        fps.sort((a, b) => {
          if (a.floorplan_units_available === null) return 1;
          if (b.floorplan_units_available === null) return -1;
          return (
            parseInt(b.floorplan_units_available) -
            parseInt(a.floorplan_units_available)
          );
        });
      }

      return fps;
    },
    useEngrainMap() {
      var atts = this.options.rentpress_shortcode_atts;
      var codes = [];
      var ids = [];
      var toggles = [];
      for (const key in atts) {
        if (!isNaN(key)) {
          toggles.push(atts[key].toUpperCase());
        }
        if (key === "property_codes") {
          codes.push(...atts[key].split(","));
        }
        if (key === "property_post_ids") {
          ids.push(atts[key]);
        }
      }
      if (codes.length > 1) {
        return false;
      }
      if (ids.length > 1) {
        return false;
      }
      if (!toggles.includes("ENGRAIN") && !this.useEngrain) {
        return false;
      }
      return true;
    },
    engrainMapLink() {
      var link = "";
      if (this.useEngrainMap) {
        var codes = [];
        for (var i = this.floorplans.length - 1; i >= 0; i--) {
          var floorplan = this.floorplans[i];
          if (!codes.includes(floorplan.floorplan_parent_property_code)) {
            codes.push(floorplan.floorplan_parent_property_code);
          }
        }
        var property_add_on_fields = JSON.parse(
          this.floorplans[0].floorplan_parent_property_add_on_fields
        );
        if (codes.length <= 1 && property_add_on_fields !== null) {
          if (
            typeof property_add_on_fields.property_engrain_map !== "undefined"
              ? property_add_on_fields.property_engrain_map
              : ""
          ) {
            link = property_add_on_fields.property_engrain_map
              ? property_add_on_fields.property_engrain_map
              : "";
          }
        }
      }
      return link;
    },
    filteredFloorplanUnits() {
      var filteredUnits = [];
      if (this.filteredFloorplans) {
        for (var i = this.filteredFloorplans.length - 1; i >= 0; i--) {
          filteredUnits.push(
            ...this.filteredFloorplans[i].units.map(a =>
              a.unit_name.substring(0, 1) === "0"
                ? a.unit_name.replace(/^0+/, "")
                : a.unit_name
            )
          );
        }
      }
      return filteredUnits;
    },
    propertyColors() {
      var propColorsArray = {};
      if (this.parentproperties) {
        for (var i = this.parentproperties.length - 1; i >= 0; i--) {
          var prop = this.parentproperties[i];
          if (
            typeof prop.property_accent_color !== "undefined" &&
            prop.property_use_accent_color === "1"
          ) {
            propColorsArray[prop.property_code] = prop.property_accent_color;
          } else {
            propColorsArray[
              prop.property_code
            ] = this.options.rentpress_accent_color_section_input;
          }
        }
      }
      return propColorsArray;
    }
  },
  methods: {
    onResize() {
      this.windowHorizontalSize = window.innerWidth;
    },
    selectThisFloorplan: function(selection) {
      if (this.useModals) {
        this.selectedFloorplan = selection;
        this.dialog = true;
        this.sendGAEvent(
          "View Floor Plan",
          "click",
          this.selectedFloorplan.floorplan_name
        );
      }
    },
    navigateToPost: function(post_id) {
      window.location = "/?page_id=" + post_id;
    },
    updateParams() {
      const url = new URL(
        document.location.origin + document.location.pathname
      );
      if (this.selectedPrice) {
        url.searchParams.set(
          "price",
          this.selectedPrice.replace(/[^0-9]/g, "")
        );
      }
      if (this.selectedBedrooms) {
        this.selectedBedrooms.forEach(a =>
          url.searchParams.append("bedrooms", a)
        );
      }
      if (this.dateSelectorValue) {
        url.searchParams.set("move_in_date", this.dateSelectorValue);
      }
      window.history.pushState({}, "", url);

      this.sendGAEvent(
        "Floor Plan Search",
        "filter",
        "Bed " + this.selectedBedrooms + " - " + this.selectedPrice
      );
    }
  },
  watch: {
    dialog: function(isOpen) {
      if (!isOpen) {
        this.selectedFloorplan = {};
      }
    }
  },
  mounted: function() {
    this.onResize();
    let bed_types = [];
    let availablePrices = [];
    if (
      typeof this.options.rentpress_shortcode_atts?.features_filter !==
      "undefined"
    ) {
      let all_floorplan_features = [];
      let floorplan_filter_values = [];
      let features_filter = this.options.rentpress_shortcode_atts.features_filter
        .replaceAll("%%", " ")
        .split("|");
      for (let i = this.floorplans.length - 1; i >= 0; i--) {
        let features = this.floorplans[i].floorplan_features;
        if (features != null) {
          all_floorplan_features.push(...JSON.parse(features));
        }
      }
      all_floorplan_features = [...new Set(all_floorplan_features)];
      if (all_floorplan_features.length) {
        floorplan_filter_values = all_floorplan_features.filter(element =>
          features_filter
            .map(v => v.toLowerCase())
            .includes(element.toLowerCase())
        );
      }
      this.floorplanFeatureFilterValues = floorplan_filter_values.length
        ? floorplan_filter_values
        : [];
    }

    for (let j = this.floorplans.length - 1; j >= 0; j--) {
      bed_types = [
        ...bed_types,
        parseInt(this.floorplans[j].floorplan_bedrooms)
      ];

      if (
        this.floorplans[j].floorplan_rent_type_selection_cost &&
        this.floorplans[j].floorplan_rent_type_selection_cost > 100
      ) {
        availablePrices = [
          ...availablePrices,
          parseInt(this.floorplans[j].floorplan_rent_type_selection_cost)
        ];
      }
    }

    if (availablePrices.length > 0) {
      var minimumPrice = Math.ceil(Math.min(...availablePrices) / 50) * 50;
      var maximumPrice = Math.floor(Math.max(...availablePrices) / 50) * 50;
      this.priceRanges = [this.formatter.format(minimumPrice)];
      while (minimumPrice < maximumPrice) {
        this.priceRanges.push(this.formatter.format((minimumPrice += 50)));
      }
    }

    // Set up possible bedrooms array
    let possibleBedroomsArray = bed_types
      .filter((item, pos) => bed_types.indexOf(item) === pos)
      .sort();

    possibleBedroomsArray.forEach(bedroomType => {
      let text = bedroomType == 0 ? "Studio" : bedroomType + " Bedroom";
      this.possibleBedrooms.push({
        text: text,
        value: bedroomType
      });
    });

    for (var m = this.parentproperties.length - 1; m >= 0; m--) {
      let prop = this.parentproperties[m];
      this.possibleProperties.push({
        text: prop.property_name,
        value: prop.property_code
      });
    }

    this.selectedSort = this.options.default_sort;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams
      .getAll("bedrooms")
      .forEach(a => this.selectedBedrooms.push(parseInt(a)));
    let price = urlParams.get("price");
    this.selectedPrice =
      price != null && Number.isInteger(Number(price))
        ? this.formatter.format(price)
        : "";
    this.dateSelectorValue = urlParams.get("move_in_date");
  },
  components: {
    singleFloorplanPage,
    floorplanCard,
    sightMap
  }
};
</script>

<style>
/* Setting padding to 0 prevents hiding last week of the month on mobile devices */
div.v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}

/* Top Filter Classes */
.rentpress-top-floorplan-search-filter-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  gap: 2em;
}

.rentpress-top-floorplan-search-filters {
  display: flex;
  flex-direction: column;
}

.rentpress-top-floorplan-search-filters > div {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.rentpress-top-floorplan-search-filters button,
.rentpress-floorplan-sidebar-search-container button {
  padding: 0;
  margin-left: 10px;
}

.rentpress-shortcode-wrapper .v-list-item__title {
  font-family: var(--user-font-fam);
}

/* Top Filter Queries */
.rentpress-floorplan-search-container
  .rentpress-floorplan-card-wrapper
  .rentpress-floorplan-card-container {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 95vw;
  grid-template-rows: 1fr;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .rentpress-floorplan-search-container
    .rentpress-floorplan-card-wrapper
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(2, 48%);
  }
}

@media (min-width: 960px) {
  .rentpress-floorplan-search-container
    .rentpress-floorplan-card-wrapper
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(3, 33%);
  }
}

@media (min-width: 1500px) {
  .rentpress-floorplan-search-container
    .rentpress-floorplan-card-wrapper
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(4, 24%);
  }
}

@media (min-width: 1921px) {
  .rentpress-floorplan-search-container
    .rentpress-floorplan-card-wrapper
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(5, 19%);
  }
}

@media (max-width: 960px) {
  .rentpress-floorplan-search-container {
    grid-template-columns: 95vw;
    grid-template-rows: auto auto auto;
    grid-template-areas: "top-filters-button" "top-filters" "floorplan-grid";
  }

  .rentpress-floorplan-search-container
    .rentpress-floorplan-top-filters-button {
    grid-area: top-filters-button;
  }
}

/* Sidebar Queries */

.rentpress-floorplan-sidebar-search-container
  .rentpress-floorplan-card-container {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 95vw;
  grid-template-rows: 1fr;
  justify-content: space-around;
}

.rentpress-browse-floorplans-input {
  margin-bottom: 20px;
}

.rentpress-browse-floorplans-input,
.rentpress-browse-map-input {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 600px) {
  .rentpress-floorplan-sidebar-search-container
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(2, 48vw);
  }
  .rentpress-floorplan-search-container
    .rentpress-floorplan-search-has-map
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(1, 100%);
  }
}

@media (min-width: 960px) {
  .rentpress-floorplan-sidebar-search-container {
    display: grid;
    grid-template-columns:
      [page-start] min-content [sidebar] minmax(280px, 25vw)
      [floorplan-grid] 70vw [page-end] min-content;
    justify-content: space-around;
  }
  .rentpress-floorplan-sidebar-search-container .rentpress-floorplan-sidebar {
    grid-column: sidebar / floorplan-grid;
  }
  .rentpress-floorplan-sidebar-search-container
    .rentpress-floorplan-card-container {
    grid-column: floorplan-grid / page-end;
  }
  .rentpress-floorplan-sidebar-search-container
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(2, 45%);
  }
  .rentpress-floorplan-search-container
    .rentpress-floorplan-search-has-map
    .rentpress-floorplan-card-container {
    grid-template-columns: repeat(2, 50%);
  }
  @media (min-width: 1240px) {
    .rentpress-floorplan-sidebar-search-container {
      grid-template-columns: [page-start] 1em [sidebar] minmax(200px, 350px) [floorplan-grid] 71vw [page-end] 1em;
    }
    .rentpress-floorplan-sidebar-search-container
      .rentpress-floorplan-card-container {
      grid-template-columns: repeat(3, 32%);
    }
  }
  @media (min-width: 1240px) {
    .rentpress-floorplan-sidebar-search-container {
      grid-template-columns: [page-start] 1em [sidebar] minmax(200px, 350px) [floorplan-grid] 71vw [page-end] 1em;
    }
    .rentpress-floorplan-sidebar-search-container
      .rentpress-floorplan-card-container {
      grid-template-columns: repeat(3, 32%);
    }
  }
  @media (min-width: 1921px) {
    .rentpress-floorplan-sidebar-search-container
      .rentpress-floorplan-card-container {
      grid-template-columns: repeat(4, 24%);
    }
  }
}
</style>
