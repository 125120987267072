<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center">
        <p class="rentpress-inherited-font-family text-h3">{{ title }}</p>
        <p class="rentpress-inherited-font-family text-h5">{{ message }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="property">
        <p class="rentpress-inherited-font-family text-subtitle-1">
          Database columns
        </p>
        <v-list>
          <v-list-item
            v-for="(propertyData, propertyKey) in property"
            :key="propertyKey"
          >
            <div v-if="propertyKey != 'floorplans'">
              {{ propertyKey }} : {{ propertyData }}
            </div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    message: String,
    title: String,
    property: {}
  }
};
</script>
